import { action } from "typesafe-actions";
import { purchaseOrderActionTypes } from "./po.model";

export const getPurchaseOrderDataRequest = (req) =>
  action(purchaseOrderActionTypes.GETPURCHASEORDER_REQUEST, req);
export const getPurchaseOrderDataSuccess = (res) =>
  action(purchaseOrderActionTypes.GETPURCHASEORDER_SUCCESS, res);
export const getPurchaseOrderDataFailure = (err) =>
  action(purchaseOrderActionTypes.GETPURCHASEORDER_FAILURE, err);

export const postPurchaseOrderDataRequest = (req) =>
  action(purchaseOrderActionTypes.POSTPURCHASEORDER_REQUEST, req);
export const postPurchaseOrderDataSuccess = (res) =>
  action(purchaseOrderActionTypes.POSTPURCHASEORDER_SUCCESS, res);
export const postPurchaseOrderDataFailure = (err) =>
  action(purchaseOrderActionTypes.POSTPURCHASEORDER_FAILURE, err);

export const updatePurchaseOrderDataRequest = (req) =>
  action(purchaseOrderActionTypes.UPDATEPURCHASEORDER_REQUEST, req);
export const updatePurchaseOrderDataSuccess = (res) =>
  action(purchaseOrderActionTypes.UPDATEPURCHASEORDER_SUCCESS, res);
export const updatePurchaseOrderDataFailure = (err) =>
  action(purchaseOrderActionTypes.UPDATEPURCHASEORDER_FAILURE, err);

export const getPurchaseOrderByIdDataRequest = (req) =>
  action(purchaseOrderActionTypes.GETPURCHASEORDERBYID_REQUEST, req);
export const getPurchaseOrderByIdDataSuccess = (res) =>
  action(purchaseOrderActionTypes.GETPURCHASEORDERBYID_SUCCESS, res);
export const getPurchaseOrderByIdDataFailure = (err) =>
  action(purchaseOrderActionTypes.GETPURCHASEORDERBYID_FAILURE, err);

export const getAllPurchaseOrderDataRequest = (req) =>
  action(purchaseOrderActionTypes.GETALLPURCHASEORDER_REQUEST, req);
export const getAllPurchaseOrderDataSuccess = (res) =>
  action(purchaseOrderActionTypes.GETALLPURCHASEORDER_SUCCESS, res);
export const getAllPurchaseOrderDataFailure = (err) =>
  action(purchaseOrderActionTypes.GETALLPURCHASEORDER_FAILURE, err);

export const uploadPdfSendRequest = (req) =>
  action(purchaseOrderActionTypes.UPLOADPDF_REQUEST, req);
export const uploadPdfSendSuccess = (res) =>
  action(purchaseOrderActionTypes.UPLOADPDF_SUCCESS, res);
export const uploadPdfSendFailure = (err) =>
  action(purchaseOrderActionTypes.UPLOADPDF_FAILURE, err);

export const postPurchasePaymentDataRequest = (req) =>
  action(purchaseOrderActionTypes.ORDERPAYMENTSEND_REQUEST, req);
export const postPurchasePaymentDataSuccess = (res) =>
  action(purchaseOrderActionTypes.ORDERPAYMENTSEND_SUCCESS, res);
export const postPurchasePaymentDataFailure = (err) =>
  action(purchaseOrderActionTypes.ORDERPAYMENTSEND_FAILURE, err);

export const getPurchasePaymentRequest = (req) =>
  action(purchaseOrderActionTypes.GETPAYMENTLIST_REQUEST, req);
export const getPurchasePaymentSuccess = (res) =>
  action(purchaseOrderActionTypes.GETPAYMENTLIST_SUCCESS, res);
export const getPurchasePaymentFailure = (err) =>
  action(purchaseOrderActionTypes.GETPAYMENTLIST_FAILURE, err);

export const getFilteredPaymentRequest = (req) =>
  action(purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_REQUEST, req);
export const getFilteredPaymentSuccess = (res) =>
  action(purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_SUCCESS, res);
export const getFilteredPaymentFailure = (err) =>
  action(purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_FAILURE, err);


export const poStateReset = (req) =>
  action(purchaseOrderActionTypes.PO_STATERESET_REQUEST, req);
