import { dashboardActionTypes } from "./dashboard.model";
import * as dashboardAction from "./dashboard.action";
import { getcardsApi, getDashboardApi, getDueDayApi, getOrdercardsApi, getPurchasecardsApi, getPurchasePaymentcardsApi } from "./dashboard.api";
import { call, put, takeEvery } from "redux-saga/effects";
function* handleGetCardData(action) {
  try {
    const response = yield call(getcardsApi, action.payload);
    yield put(dashboardAction.getCardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getCardDataFailure(e));
  }
}

function* handleGetDashBoardData(action) {
  try {
    const response = yield call(getDashboardApi, action.payload);
    yield put(dashboardAction.getDashBoardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getDashBoardDataFailure(e));
  }
}

function* handleGetDuePayment(action) {
  try {
    const response = yield call(getDueDayApi, action.payload);
    yield put(dashboardAction.dueDayDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.dueDayDataFailure(e));
  }
}
function* handleGetOrderCardData(action) {
  try {
    const response = yield call(getOrdercardsApi, action.payload);
    yield put(dashboardAction.getOrderCardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getOrderCardDataFailure(e));
  }
}

function* handleGetPurchaseCardData(action) {
  try {
    const response = yield call(getPurchasecardsApi, action.payload);
    yield put(dashboardAction.getPurchseCardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getPurchseCardDataFailure(e));
  }
}

function* handleGetPurchasePaymentCardData(action) {
  try {
    const response = yield call(getPurchasePaymentcardsApi, action.payload);
    yield put(dashboardAction.getPurchsePaymentCardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getPurchsePaymentCardDataFailure(e));
  }
}

export function* dashboardSaga() {
  yield takeEvery(dashboardActionTypes.GETCARDDATA_REQUEST, handleGetCardData);
  yield takeEvery(
    dashboardActionTypes.GETDASHBOARDDATA_REQUEST,
    handleGetDashBoardData
  );
  yield takeEvery(
    dashboardActionTypes.PAYMENTDUEDAYS_REQUEST,
    handleGetDuePayment
  );

  yield takeEvery(dashboardActionTypes.GETORDERCARDDATA_REQUEST, handleGetOrderCardData);
  yield takeEvery(dashboardActionTypes.GETPURCHASECARDDATA_REQUEST, handleGetPurchaseCardData);
  yield takeEvery(dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_REQUEST, handleGetPurchasePaymentCardData);
}
