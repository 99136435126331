import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
// import { UserActionTypes } from "./user/user.model";
import { AuthReducer } from "./auth/auth.reducer";
// import { SettingReducer } from "./settings/settings.reducer";
import { UserReducer } from "./user/user.reducer";
import { menu } from "./menu/menu.reducer";
import { AuthActionTypes } from "./auth/auth.model";
import { CategoryAddReducer } from "./category/category.reducer";
import { BrandReducer } from "./brand/brand.reducer";
import { productReducer } from "./product/product.reducer";
import { orderReducer } from "./order/order.reducer";
import { InventryReducer } from "./InventryScreen/Reducer";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { PurchaseOrderReducer } from "./PurchaseOrder/po.reducer";
import { PurchaseCustomerReducer } from "./PurchaseCompany/pc.reducer";
import { RiseTockenReducer } from "./risetocken/rise.reducer";

const appReducer = combineReducers({
  AuthReducer,
  UserReducer,
  menu,
  CategoryAddReducer,
  BrandReducer,
  productReducer,
  orderReducer,
  InventryReducer,
  dashboardReducer,
  PurchaseOrderReducer,
  PurchaseCustomerReducer,
  RiseTockenReducer
});

const persistConfig = {
  key: "socialapp",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === AuthActionTypes.CLEAR_USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
