import { all } from "redux-saga/effects";
import { AuthSaga } from "./auth/auth.saga";
import { SettingSaga } from "./settings/settings.saga";
import { UserSaga } from "./user/user.saga";
import { CategoryAddSaga } from "./category/category.saga";
import { BrandSaga } from "./brand/brand.saga";
import { productSaga } from "./product/product.saga";
import { orderSaga } from "./order/order.saga";
import { InventrySaga } from "./InventryScreen/Saga";
import { dashboardSaga } from "./dashboard/reducer.saga";
import { PurchaseOrderSaga } from "./PurchaseOrder/po.saga";
import { PurchaseCompanySaga } from "./PurchaseCompany/pc.saga";
import { riseTockenSaga } from "./risetocken/rise.saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    SettingSaga(),
    UserSaga(),
    CategoryAddSaga(),
    BrandSaga(),
    productSaga(),
    orderSaga(),
    InventrySaga(),
    dashboardSaga(),
    PurchaseOrderSaga(),
    PurchaseCompanySaga(),
    riseTockenSaga()
  ]);
}
