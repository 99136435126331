import brand1 from "../../assets/imgs/brands/brand-1.jpg";
import brand2 from "../../assets/imgs/brands/brand-2.jpg";
import brand3 from "../../assets/imgs/brands/brand-3.jpg";
import brand4 from "../../assets/imgs/brands/brand-4.jpg";
import brand5 from "../../assets/imgs/brands/brand-5.jpg";
import brand6 from "../../assets/imgs/brands/brand-6.jpg";
import brand7 from "../../assets/imgs/brands/brand-7.jpg";
import brand8 from "../../assets/imgs/brands/brand-8.jpg";
import brand9 from "../../assets/imgs/brands/brand-9.jpg";
import brand10 from "../../assets/imgs/brands/brand-10.jpg";
import brand11 from "../../assets/imgs/brands/brand-11.jpg";
import brand12 from "../../assets/imgs/brands/brand-12.jpg";
import brand13 from "../../assets/imgs/brands/brand-13.jpg";
import brand14 from "../../assets/imgs/brands/brand-14.jpg";
import brand15 from "../../assets/imgs/brands/brand-15.jpg";
import brand16 from "../../assets/imgs/brands/brand-16.jpg";
import brand17 from "../../assets/imgs/brands/brand-17.jpg";
import brand18 from "../../assets/imgs/brands/brand-18.jpg";

import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { BrandAddListValiator } from "../../validators/Validators";
import { BrandSelector } from "../../store/brand/brand.model";
import { deleteBrandRequest, getBrandRequest, postBrandRequest, updateBrandRequest } from "../../store/brand/brand.action";

function Pagebrands(){

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(BrandAddListValiator),
  });

  // Selectors
  const { getBrandData, sendSuccess, BrandUpdatedSuccess, brandDeleteSuccess } =
    useSelector(BrandSelector);

  // States
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages1 = Math.ceil(getBrandData?.count / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const onSubmitHandler = (formData) => {
  
    if (id === 0) {
      dispatch(postBrandRequest(formData));
    } else {
      dispatch(updateBrandRequest({ formData, id }));
      setId(0);
    }
  };

  const handleEdit = (cat) => {
    setId(cat.id);
    reset({
      name: cat.name,
      description: cat.description,
    });
  };

  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;  
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1 && currentPage>3
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = currentPage>3 &&totalPages1 != currentPage ? "..." : null;
  const lastPage = currentPage>3 &&totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const handleDelete = (id) => {
    dispatch(deleteBrandRequest(id));
  };

  useEffect(() => {
    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getBrandRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);

  useEffect(() => {
    if (sendSuccess || BrandUpdatedSuccess || brandDeleteSuccess) {
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getBrandRequest(payload));
      reset({
        description: "",
        name: "",
      });
      setId(0);
    }
  }, [sendSuccess, BrandUpdatedSuccess, brandDeleteSuccess, dispatch, reset]);

    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Brand</h2>
            <p>Add, edit or delete a Brand </p>
          </div>
          <div>
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search. . ."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          {/* <div><a className="btn btn-primary" href="#"><i className="text-muted material-icons md-post_add"></i>Add New Brand</a></div> */}
        </div>
        <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  {/* Form for adding/updating categories */}
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-4">
                      <label className="form-label" htmlFor="product_name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        id="product_name"
                        type="text"
                        placeholder="Type here"
                        {...register("name")}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        placeholder="Type here"
                        {...register("description")}
                      ></textarea>
                      {errors.description && (
                        <p className="text-danger">
                          {errors.description.message}
                        </p>
                      )}
                    </div>
                    <div className="d-grid">
                      <button className="btn btn-primary" type="submit">
                        {id === 0 ? "Create category" : "Update category"}
                      </button>
                    </div>
                  </form>
                </Col>

                <Col md={9}>
                  {/* Category List */}
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getBrandData?.data &&
                          getBrandData?.data?.map((ele, ind) => {
                            return (
                              <tr key={ele.id}>
                                <td>{ind + 1}</td>
                                <td>{ele.name}</td>
                                <td>{ele.description}</td>
                                <td>
                                  {dayjs(ele.created_at).format("DD-MM-YY")}
                                </td>
                                <td>
                                  <div className="dropdown">
                                    <a
                                      className="btn btn-light rounded btn-sm font-sm"
                                      href="#"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="material-icons md-more_horiz"></i>
                                    </a>
                                    <div className="dropdown-menu">
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleEdit(ele)}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="dropdown-item text-danger"
                                        onClick={() => handleDelete(ele.id)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="material-icons md-chevron_left"></i>
                          </a>
                        </li>

                        {pagesToDisplay.map((page) => {
                          return page != "..." ? (
                            <li
                              key={page}
                              className={`page-item b ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => handlePageChange(page)}
                              >
                                {page}
                              </a>
                            </li>
                          ) : page == "..." ? (
                            <li
                              key={page}
                              className={`page-item ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a className="page-link" href="#">
                                {page}
                              </a>
                            </li>
                          ) : (
                            ""
                          );
                        })}

                        <li
                          className={`page-item ${
                            currentPage === totalPages1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <i className="material-icons md-chevron_right"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        {/* <div className="card mb-4">
          <header className="card-header">
            <Row className="gx-3">
              <Col lg={4} className="mb-lg-0 mb-15 me-auto">
                <input className="form-control" type="text" placeholder="Search..."/>
              </Col>
              <Col lg={2} sm={6}>
                <div className="custom_select">
                  <select className="form-select select-nice">
                    <option selected="">Categories</option>
                    <option>Technology</option>
                    <option>Fashion</option>
                    <option>Home Decor</option>
                    <option>Healthy</option>
                    <option>Travel</option>
                    <option>Auto-car</option>
                  </select>
                </div>
              </Col>
              <Col lg={2} sm={6}>
                <input className="form-control" type="date" name=""/>
              </Col>
            </Row>
          </header>
          <div className="card-body">
            <Row className="gx-3">
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand1} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Cardinal</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand2} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">BirdFly</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand3} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Cocorico</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand4} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Yogilist</h6><a href="#"> 87 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand5} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Acerie</h6><a href="#"> 10 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand6} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Shivakin</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand7} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Acera</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand8} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Lion electronics</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand9} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">TwoHand</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand10} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Kiaomin</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand11} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Nokine</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand12} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand13} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand14} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand15} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand16} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand17} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand18} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
            </Row>
          </div>
        </div> */}
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagebrands;