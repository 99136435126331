export const RiseTockenActionTypes = {
  RISETOCKEN_GET_REQUEST: "@product/RISETOCKEN_GET_REQUEST",
  RISETOCKEN_GET_SUCCESS: "@product/RISETOCKEN_GET_SUCCESS",
  RISETOCKEN_GET_FAILURE: "@product/RISETOCKEN_GET_FAILURE",

  RISETOCKEN_POST_REQUEST: "@product/RISETOCKEN_POST_REQUEST",
  RISETOCKEN_POST_SUCCESS: "@product/RISETOCKEN_POST_SUCCESS",
  RISETOCKEN_POST_FAILURE: "@product/RISETOCKEN_POST_FAILURE",

  RISETOCKEN_UPDATE_REQUEST: "@product/RISETOCKEN_UPDATE_REQUEST",
  RISETOCKEN_UPDATE_SUCCESS: "@product/RISETOCKEN_UPDATE_SUCCESS",
  RISETOCKEN_UPDATE_FAILURE: "@product/RISETOCKEN_UPDATE_FAILURE",


};

export const RiseTockenInitialState = {
  getLoading:false,
  tocken:[],
  postLoading:false,
  postSuccess:false,
  updateLoading:false,
  updateSuccess:false

};
export const RiseTockenSelector = (state) => state.RiseTockenReducer;
