import { decryptdata } from "../../utils/encrypt&decrypt";
import {
  purchaseOrderActionTypes,
  purchaseOrderInitialState,
} from "./po.model";

export const reducer = (state = purchaseOrderInitialState, action) => {
  switch (action.type) {
    case purchaseOrderActionTypes.GETPURCHASEORDER_REQUEST:
      return {
        ...state,
        Loading: true,
      };
    case purchaseOrderActionTypes.GETPURCHASEORDER_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      return {
        ...state,
        Loading: false,
        invoiceData: payloaddatas1,
      };
    case purchaseOrderActionTypes.GETPURCHASEORDER_FAILURE:
      return {
        ...state,
        Loading: false,
      };

    case purchaseOrderActionTypes.POSTPURCHASEORDER_REQUEST:
      return {
        ...state,
        PostLoading: true,
        PostSuccess: false,
      };
    case purchaseOrderActionTypes.POSTPURCHASEORDER_SUCCESS:
      return {
        ...state,
        PostLoading: false,
        PostSuccess: true,
      };
    case purchaseOrderActionTypes.POSTPURCHASEORDER_FAILURE:
      return {
        ...state,
        PostLoading: false,
        PostSuccess: false,
      };

    case purchaseOrderActionTypes.UPDATEPURCHASEORDER_REQUEST:
      return {
        ...state,
        UpdateLoading: true,
        UpdateSuccess: false,
      };
    case purchaseOrderActionTypes.UPDATEPURCHASEORDER_SUCCESS:
      return {
        ...state,
        UpdateLoading: false,
        UpdateSuccess: true,
      };
    case purchaseOrderActionTypes.UPDATEPURCHASEORDER_FAILURE:
      return {
        ...state,
        UpdateLoading: false,
        UpdateSuccess: false,
      };

    case purchaseOrderActionTypes.GETPURCHASEORDERBYID_REQUEST:
      return {
        ...state,
        dataByIdLoad: true,
      };
    case purchaseOrderActionTypes.GETPURCHASEORDERBYID_SUCCESS:
      console.log(
        action?.payload?.data?.datas,
        process.env.REACT_APP_SECRETKEY,
        "jkmddls"
      );
      let payloaddatas2 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(action?.payload?.data?.datas, payloaddatas2, "yfuoisdf");
      return {
        ...state,
        dataByIdLoad: false,
        PodataById: payloaddatas2?.response,
      };
    case purchaseOrderActionTypes.GETPURCHASEORDERBYID_FAILURE:
      return {
        ...state,
        dataByIdLoad: false,
      };
    case purchaseOrderActionTypes.GETALLPURCHASEORDER_REQUEST:
      return {
        ...state,
        allLoading: true,
      };
    case purchaseOrderActionTypes.GETALLPURCHASEORDER_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas3 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      return {
        ...state,
        allLoading: false,
        allInvoice: payloaddatas3,
      };
    case purchaseOrderActionTypes.GETALLPURCHASEORDER_FAILURE:
      return {
        ...state,
        allLoading: false,
      };
    case purchaseOrderActionTypes.UPLOADPDF_REQUEST:
      return {
        ...state,
        uploadPdfLoad: true,
        uploadPdfSuccess: false,
      };
    case purchaseOrderActionTypes.UPLOADPDF_SUCCESS:
      return {
        ...state,
        uploadPdfLoad: false,
        uploadPdfSuccess: true,
      };
    case purchaseOrderActionTypes.UPLOADPDF_FAILURE:
      return {
        ...state,
        uploadPdfLoad: false,
        uploadPdfSuccess: false,
      };

      case purchaseOrderActionTypes.ORDERPAYMENTSEND_REQUEST:
      return {
        ...state,
        postPaymentLoading: true,
        postPaymentSuccess: false,
      };
    case purchaseOrderActionTypes.ORDERPAYMENTSEND_SUCCESS:
      return {
        ...state,
        postPaymentLoading: false,
        postPaymentSuccess: true,
      };
    case purchaseOrderActionTypes.ORDERPAYMENTSEND_FAILURE:
      return {
        ...state,
        postPaymentLoading: false,
        postPaymentSuccess: false,
      };


      case purchaseOrderActionTypes.GETPAYMENTLIST_REQUEST:
      return {
        ...state,
        getPaymentLoading: true,
      };
    case purchaseOrderActionTypes.GETPAYMENTLIST_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      return {
        ...state,
       
        getPaymentLoading: false,
        purchasePayment: payloaddatas
      };
    case purchaseOrderActionTypes.GETPAYMENTLIST_FAILURE:
      return {
        ...state,
        getPaymentLoading: false,
      };

      case purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_REQUEST:
        return {
          ...state,
          getFilterPaymentLoading: true,
        };
      case purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_SUCCESS:
        console.log(action?.payload, process.env.SECRET_KEY, "jkmddfls");
        let payloaddatasss = JSON.parse(
          decryptdata(
            action?.payload?.data?.datas,
            "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
          )
        );
        console.log(payloaddatasss, "dalsjflksdlfj")
        return {
          ...state,
         
          getFilterPaymentLoading:false,   
          filteredPayment:payloaddatasss,
        };
      case purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_FAILURE:
        return {
          ...state,
          getFilterPaymentLoading: false,
        };
    case purchaseOrderActionTypes.PO_STATERESET_REQUEST:
      return {
        ...state,
        Loading: false,
        invoiceData: [],
        PostLoading: false,
        PostSuccess: false,
        UpdateLoading: false,
        UpdateSuccess: false,
        dataByIdLoad: false,
        PodataById: [],
        allLoading: false,
        allInvoice: [],
        uploadPdfLoad: false,
        uploadPdfSuccess: false,
        postPaymentLoading: false,
        postPaymentSuccess: false,
        getPaymentLoading: false,
        purchasePayment: [],
        getFilterPaymentLoading:false,   
        filteredPayment:[],
      };

    default:
      return state;
  }
};

export { reducer as PurchaseOrderReducer };
