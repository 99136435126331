import { action } from "typesafe-actions";
import { orderActionTypes } from "./order.model";

export const getUserOrderDataRequest = (req) =>
  action(orderActionTypes.GETUSERORDER_REQUEST, req);
export const getUserOrderDataSuccess = (res) =>
  action(orderActionTypes.GETUSERORDER_SUCCESS, res);
export const getUserOrderDataFailure = (err) =>
  action(orderActionTypes.GETUSERORDER_FAILURE, err);

export const getAllUserOrderDataRequest = (req) =>
  action(orderActionTypes.GETALLUSERORDER_REQUEST, req);
export const getAllUserOrderDataSuccess = (res) =>
  action(orderActionTypes.GETALLUSERORDER_SUCCESS, res);
export const getAllUserOrderDataFailure = (err) =>
  action(orderActionTypes.GETALLUSERORDER_FAILURE, err);

export const orderDispatchRequest = (req) =>
  action(orderActionTypes.ORDERDISPATCH_REQUEST, req);
export const orderDispatchSuccess = (res) =>
  action(orderActionTypes.ORDERDISPATCH_SUCCESS, res);
export const orderDispatchFailure = (err) =>
  action(orderActionTypes.ORDERDISPATCH_FAILURE, err);

export const orderDetailGetRequest = (req) =>
  action(orderActionTypes.ORDERDETAILBYID_REQUEST, req);
export const orderDetailGetSuccess = (res) =>
  action(orderActionTypes.ORDERDETAILBYID_SUCCESS, res);
export const orderDetailGetFailure = (err) =>
  action(orderActionTypes.ORDERDETAILBYID_FAILURE, err);

export const UpdateOrderStatusRequest = (req) =>
  action(orderActionTypes.ORDERDSTATUSUPDADE_REQUEST, req);
export const UpdateOrderStatusSuccess = (res) =>
  action(orderActionTypes.ORDERDSTATUSUPDADE_SUCCESS, res);
export const UpdateOrderStatusFailure = (err) =>
  action(orderActionTypes.ORDERDETAILBYID_FAILURE, err);

export const creditDueDataRequest = (req) =>
  action(orderActionTypes.CREDITDUEPAYDATA_REQUEST, req);
export const creditDueDataSuccess = (res) =>
  action(orderActionTypes.CREDITDUEPAYDATA_SUCCESS, res);
export const creditDueDataFailure = (err) =>
  action(orderActionTypes.CREDITDUEPAYDATA_FAILURE, err);

export const sreditDueDataRequest = (req) =>
  action(orderActionTypes.SREDITDUEPAYDATA_REQUEST, req);
export const sreditDueDataSuccess = (res) =>
  action(orderActionTypes.SREDITDUEPAYDATA_SUCCESS, res);
export const sreditDueDataFailure = (err) =>
  action(orderActionTypes.SREDITDUEPAYDATA_FAILURE, err);

export const generatePDFRequest = (req) =>
  action(orderActionTypes.GENERATE_REQUEST, req);
export const generatePDFSuccess = (res) =>
  action(orderActionTypes.GENERATE_SUCCESS, res);
export const generatePDFFailure = (err) =>
  action(orderActionTypes.GENERATE_FAILURE, err);

export const checkOutDataRequest = (req) =>
  action(orderActionTypes.CHECKOUT_POST_REQUEST, req);
export const checkOutDataSuccess = (res) =>
  action(orderActionTypes.CHECKOUT_POST_SUCCESS, res);
export const checkOutDataFailure = (err) =>
  action(orderActionTypes.CHECKOUT_POST_FAILURE, err);

export const getUserOrderDetailByIdRequest = (req) =>
  action(orderActionTypes.GETORDERDATABYID_REQUEST, req);
export const getUserOrderDetailByIdSuccess = (res) =>
  action(orderActionTypes.GETORDERDATABYID_SUCCESS, res);
export const getUserOrderDetailByIdFailure = (err) =>
  action(orderActionTypes.GETORDERDATABYID_FAILURE, err);

export const postPaymentDataRequest = (req) =>
  action(orderActionTypes.ORDERPAYMENTSEND_REQUEST, req);
export const postPaymentDataSuccess = (res) =>
  action(orderActionTypes.ORDERPAYMENTSEND_SUCCESS, res);
export const postPaymentDataFailure = (err) =>
  action(orderActionTypes.ORDERPAYMENTSEND_FAILURE, err);

export const getPaymentRequest = (req) =>
  action(orderActionTypes.GETPAYMENTLIST_REQUEST, req);
export const getPaymentSuccess = (res) =>
  action(orderActionTypes.GETPAYMENTLIST_SUCCESS, res);
export const getPaymentFailure = (err) =>
  action(orderActionTypes.GETPAYMENTLIST_FAILURE, err);

export const getStatPaymentDataRequest = (req) =>
  action(orderActionTypes.GETPAYMENTSTATDATA_REQUEST, req);
export const getStatPaymentDataSuccess = (res) =>
  action(orderActionTypes.GETPAYMENTSTATDATA_SUCCESS, res);
export const getStatPaymentDataFailure = (err) =>
  action(orderActionTypes.GETPAYMENTSTATDATA_FAILURE, err);

export const updateTpriceRequest = (req) =>
  action(orderActionTypes.UPDATETRANSPORTPRICE_REQUEST, req);
export const updateTpriceSuccess = (res) =>
  action(orderActionTypes.UPDATETRANSPORTPRICE_SUCCESS, res);
export const updateTpriceFailure = (err) =>
  action(orderActionTypes.UPDATETRANSPORTPRICE_FAILURE, err);

export const weeklyPaymentReminderDataRequest = (req) =>
  action(orderActionTypes.WEEKLYPAYDATAGET_REQUEST, req);
export const weeklyPaymentReminderDataSuccess = (res) =>
  action(orderActionTypes.WEEKLYPAYDATAGET_SUCCESS, res);
export const weeklyPaymentReminderDataFailure = (err) =>
  action(orderActionTypes.WEEKLYPAYDATAGET_FAILURE, err);

export const orderStateResetRequest = (req) =>
  action(orderActionTypes.ORDERSTATE_RESET_REQUEST, req);
