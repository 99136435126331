import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  Category,
  statusClasses,
  statusData,
  supportStuts,
} from "../../utils/Constants";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductPriceRequest,
  postProductPriceRequest,
} from "../../store/product/product.action";
import { productSelector } from "../../store/product/product.model";
import {
  riseGetRequest,
  riseUpdateRequest,
} from "../../store/risetocken/rise.action";
import { RiseTockenSelector } from "../../store/risetocken/rise.model";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Support() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [supportid, setSupportid] = useState(null);
  const [status, setStatus] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [showRemarks, setShowRemarks] = useState(false);
  const [viewRemarks, setViewRemarks] = useState(null);

  const { tocken, updateSuccess } = useSelector(RiseTockenSelector);

  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
      remarks: remarks,
    };
    dispatch(riseUpdateRequest(payload));
  };
  useEffect(() => {
    dispatch(riseGetRequest());
    if (updateSuccess) {
      dispatch(riseGetRequest());
      setRemarks("");
      setShow(false);
      setSupportid(null);
      setStatus(null);
    }
  }, [updateSuccess]);

  const handleClose = () => {
    setShow(!show);
  };
  const handleRClose = () => {
    setShowRemarks(!showRemarks);
  };

  console.log(tocken, "bbb");

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <h2 className="content-title">Support</h2>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              <h4>Support List</h4>
            </div>
            <div className="card-body row">
              <div className="col-12">
                <Table>
                  <thead>
                    <tr>
                      <td>Customer Name</td>
                      <td>Type</td>
                      <td>Invoice No</td>
                      <td>Message</td>
                      <td>Status</td>
                      <td>Manage Status</td>
                      <td>View</td>
                    </tr>
                  </thead>
                  <tbody>
                    {tocken &&
                      tocken?.map((c, index) => {
                        return (
                          <tr>
                            <td>{c?.user?.name}</td>
                            <td>
                              <label>
                                {c?.type === 0 ? "order" : "payment"}
                              </label>
                            </td>
                            <td>{c?.invoice_number}</td>
                            <td>
                              <label>{c?.message}</label>
                            </td>
                            <td>
                              <span
                                className={`badge rounded-pill ${
                                  statusClasses[c?.message_status] ||
                                  "alert-secondary"
                                }`}
                              >
                                {supportStuts[c?.message_status]}
                              </span>
                            </td>
                            <td>
                              <select
                                name=""
                                className="form-select w-75"
                                onChange={
                                  (e) => {
                                    setShow(!show);
                                    setSupportid(c?.id);
                                    setStatus(e.target.value);
                                  }
                                  //onStatusChanges(e.target.value, c?.id)
                                }
                              >
                                <option className="option" value={""}>
                                  SELECT
                                </option>
                                <option className="option" value={1}>
                                  Accepted
                                </option>
                                <option className="option" value={2}>
                                  Resolved
                                </option>
                              </select>
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  setViewRemarks(c?.remarks);
                                  setShowRemarks(!showRemarks);
                                }}
                              >
                                View Remarks
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    {/* <tr>
                      <td></td>
                      <td className="text-end">
                        <button className="btn btn-primary " onClick={submit}>
                          Save
                        </button>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Remarks for Support</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex flex-column ">
                      <textarea
                        className="form-control"
                        id="address"
                        placeholder="Enter Remarks for ticket"
                        rows="3"
                        onChange={(e) => setRemarks(e.target.value)}
                      >
                        {remarks}
                      </textarea>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={(e) => onStatusChanges(status, supportid)}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal show={showRemarks} onHide={handleRClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Remarks View</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex flex-column ">
                      <Table>
                        <thead>
                          <tr>
                            <td>Remarks</td>
                          </tr>
                        </thead>
                        <tbody>
                          {viewRemarks &&
                            viewRemarks.map((remark) => (
                              <tr>
                                <td>{remark}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleRClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Support;
