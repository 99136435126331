import { call, takeEvery, put } from "redux-saga/effects";
import * as purchaseAction from "./po.action";
import Swal from "sweetalert2";
import { purchaseOrderActionTypes } from "./po.model";
const { getPOApi, postPOApi, updatePOApi, getPOByIdApi, pdfUpladApi, createPayment, getPayments, createPurchasePayment, getPruchasePayments, getFilteredPruchasePayments } = require("./po.api");

function* handlegetPOData(action) {
  try {
    const response = yield call(getPOApi, action.payload);
    yield put(purchaseAction.getPurchaseOrderDataSuccess(response));
  } catch (e) {
    yield put(purchaseAction.getPurchaseOrderDataFailure(e));
  }
}
function* handlepostPOData(action) {
  try {
    const response = yield call(postPOApi, action.payload);
    yield put(purchaseAction.postPurchaseOrderDataSuccess(response));
  } catch (e) {
    yield put(purchaseAction.postPurchaseOrderDataFailure(e));
  }
}
function* handleupdatePOData(action) {
  try {
    console.log(action.payload, "lll");
    const response = yield call(updatePOApi, action.payload);
    yield put(purchaseAction.updatePurchaseOrderDataSuccess(response));
  } catch (e) {
    yield put(purchaseAction.updatePurchaseOrderDataFailure(e));
  }
}

function* handlegetPODataBYId(action) {
  try {
    const response = yield call(getPOByIdApi, action.payload);
    yield put(purchaseAction.getPurchaseOrderByIdDataSuccess(response));
  } catch (e) {
    yield put(purchaseAction.getPurchaseOrderByIdDataFailure(e));
  }
}
function* handlegetAllPOData(action) {
  try {
    const response = yield call(getPOApi, action.payload);
    yield put(purchaseAction.getAllPurchaseOrderDataSuccess(response));
  } catch (e) {
    yield put(purchaseAction.getAllPurchaseOrderDataFailure(e));
  }
}
function* handlePostPdf(action) {
  try {
    const response = yield call(pdfUpladApi, action.payload);
    yield put(purchaseAction.uploadPdfSendSuccess(response));
  } catch (e) {
    yield put(purchaseAction.uploadPdfSendFailure(e));
  }
}
export function* handleCreatePayment(action) {
  try {
    const response = yield call(createPurchasePayment, action.payload);
    yield put(purchaseAction.postPurchasePaymentDataSuccess(response));
    Swal.fire({
      title: "Success",
      text: "payment Created Succesfully!",
      icon: "success",
    });
  } catch (err) {
    yield put(purchaseAction.postPurchasePaymentDataFailure(err));
    Swal.fire({
      title: "Error",
      text: err?.response?.data?.message,
      icon: "error",
    });
  }
}

export function* handleGetPayment(action) {
  try {
    const response = yield call(getPruchasePayments, action.payload);
    yield put(purchaseAction.getPurchasePaymentSuccess(response));
  } catch (err) {
    yield put(purchaseAction.getPurchasePaymentFailure(err));
  }
}

export function* handleGetFilteredPayment(action) {
  try {
    const response = yield call(getFilteredPruchasePayments, action.payload);
    yield put(purchaseAction.getFilteredPaymentSuccess(response));
  } catch (err) {
    yield put(purchaseAction.getFilteredPaymentFailure(err));
  }
}

export function* PurchaseOrderSaga() {
  yield takeEvery(
    purchaseOrderActionTypes.GETPURCHASEORDER_REQUEST,
    handlegetPOData
  );
  yield takeEvery(
    purchaseOrderActionTypes.GETALLPURCHASEORDER_REQUEST,
    handlegetAllPOData
  );
  yield takeEvery(
    purchaseOrderActionTypes.POSTPURCHASEORDER_REQUEST,
    handlepostPOData
  );
  yield takeEvery(
    purchaseOrderActionTypes.UPDATEPURCHASEORDER_REQUEST,
    handleupdatePOData
  );
  yield takeEvery(
    purchaseOrderActionTypes.GETPURCHASEORDERBYID_REQUEST,
    handlegetPODataBYId
  );
  yield takeEvery(
    purchaseOrderActionTypes.UPLOADPDF_REQUEST,
    handlePostPdf
  );

  yield takeEvery(
    purchaseOrderActionTypes.ORDERPAYMENTSEND_REQUEST,
    handleCreatePayment
  );
  yield takeEvery(
    purchaseOrderActionTypes.GETPAYMENTLIST_REQUEST,
    handleGetPayment
  );
  yield takeEvery(
    purchaseOrderActionTypes.GETFILTEREDPAYMENTLIST_REQUEST,
    handleGetFilteredPayment
  );
}
