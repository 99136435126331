function Footer(){
    return(
        <>
            <footer className="main-footer font-xs">
                <div className="row pb-30 pt-15">
                <div className="col-sm-6">
                Copyright &copy; 2024 SIKAN PVC Pipes & Bend. All rights reserved.
                </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;