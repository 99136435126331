export const purchaseOrderActionTypes={

    GETPURCHASEORDER_REQUEST: "@@purchaseorder/GETPURCHASEORDER_REQUEST",
    GETPURCHASEORDER_SUCCESS: "@@purchaseorder/GETPURCHASEORDER_SUCCESS",
    GETPURCHASEORDER_FAILURE: "@@purchaseorder/GETPURCHASEORDER_FAILURE",
    
    POSTPURCHASEORDER_REQUEST: "@@purchaseorder/POSTPURCHASEORDER_REQUEST",
    POSTPURCHASEORDER_SUCCESS: "@@purchaseorder/POSTPURCHASEORDER_SUCCESS",
    POSTPURCHASEORDER_FAILURE: "@@purchaseorder/POSTPURCHASEORDER_FAILURE",

    UPDATEPURCHASEORDER_REQUEST: "@@purchaseorder/UPDATEPURCHASEORDER_REQUEST",
    UPDATEPURCHASEORDER_SUCCESS: "@@purchaseorder/UPDATEPURCHASEORDER_SUCCESS",
    UPDATEPURCHASEORDER_FAILURE: "@@purchaseorder/UPDATEPURCHASEORDER_FAILURE",
   
    GETPURCHASEORDERBYID_REQUEST: "@@purchaseorder/GETPURCHASEORDERBYID_REQUEST",
    GETPURCHASEORDERBYID_SUCCESS: "@@purchaseorder/GETPURCHASEORDERBYID_SUCCESS",
    GETPURCHASEORDERBYID_FAILURE: "@@purchaseorder/GETPURCHASEORDERBYID_FAILURE",

    GETALLPURCHASEORDER_REQUEST: "@@purchaseorder/GETALLPURCHASEORDER_REQUEST",
    GETALLPURCHASEORDER_SUCCESS: "@@purchaseorder/GETALLPURCHASEORDER_SUCCESS",
    GETALLPURCHASEORDER_FAILURE: "@@purchaseorder/GETALLPURCHASEORDER_FAILURE",

    PO_STATERESET_REQUEST: "@@purchaseorder/PO_STATERESET_REQUEST",
    
    UPLOADPDF_REQUEST: "@@purchaseorder/UPLOADPDF_REQUEST",
    UPLOADPDF_SUCCESS: "@@purchaseorder/UPLOADPDF_SUCCESS",
    UPLOADPDF_FAILURE: "@@purchaseorder/UPLOADPDF_FAILURE",

    ORDERPAYMENTSEND_REQUEST: "@@purchaseorder/ORDERPAYMENTSEND_REQUEST",
    ORDERPAYMENTSEND_SUCCESS: "@@purchaseorder/ORDERPAYMENTSEND_SUCCESS",
    ORDERPAYMENTSEND_FAILURE: "@@purchaseorder/ORDERPAYMENTSEND_FAILURE",
  
  
    GETPAYMENTLIST_REQUEST: "@@purchaseorder/GETPAYMENTLIST_REQUEST",
    GETPAYMENTLIST_SUCCESS: "@@purchaseorder/GETPAYMENTLIST_SUCCESS",
    GETPAYMENTLIST_FAILURE: "@@purchaseorder/GETPAYMENTLIST_FAILURE",

    GETFILTEREDPAYMENTLIST_REQUEST: "@@purchaseorder/GETFILTEREDPAYMENTLIST_REQUEST",
    GETFILTEREDPAYMENTLIST_SUCCESS: "@@purchaseorder/GETFILTEREDPAYMENTLIST_SUCCESS",
    GETFILTEREDPAYMENTLIST_FAILURE: "@@purchaseorder/GETFILTEREDPAYMENTLIST_FAILURE",

}
export const purchaseOrderInitialState={
    Loading:false,
    invoiceData:[],
    PostLoading:false,
    PostSuccess:false,
    UpdateLoading:false,
    UpdateSuccess:false,
    dataByIdLoad:false,
    PodataById:[],
    allLoading:false,
    allInvoice:[],
    uploadPdfLoad:false,
    uploadPdfSuccess:false, 
    postPaymentLoading:false,   
    postPaymentSuccess:false,   
    getPaymentLoading:false,   
    purchasePayment:[],   
    getFilterPaymentLoading:false,   
    filteredPayment:[],
}

export const purchaseOrderSelector=(state)=>state.PurchaseOrderReducer