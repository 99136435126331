import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";
import { RiseTockenActionTypes, RiseTockenInitialState } from "./rise.model";

const reducer = (state = RiseTockenInitialState, action) => {
  switch (action.type) {
    case RiseTockenActionTypes.RISETOCKEN_GET_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case RiseTockenActionTypes.RISETOCKEN_GET_SUCCESS:
      console.log(action, "ooo");
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload?.data?.datas, secretKey)
      );
      console.log(payloadData, "ooo");

      return {
        ...state,
        getLoading: false,
        tocken: payloadData,
      };
    case RiseTockenActionTypes.RISETOCKEN_GET_FAILURE:
      return {
        ...state,
        getLoading: false,
      };

    case RiseTockenActionTypes.RISETOCKEN_UPDATE_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case RiseTockenActionTypes.RISETOCKEN_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
      };
    case RiseTockenActionTypes.RISETOCKEN_UPDATE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
};
export { reducer as RiseTockenReducer };
