import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Category } from "../../utils/Constants";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductPriceRequest,
  postProductPriceRequest,
} from "../../store/product/product.action";
import { productSelector } from "../../store/product/product.model";

function ProfitMargin() {
  const dispatch = useDispatch();
  const { cat, postPriceSuccess } = useSelector(productSelector);
  console.log(cat, "ccc");
  const [localCat, setLocalCat] = useState();
  console.log(localCat, "loo");
  const priceChamge = (price, index, name, id) => {
    setLocalCat((prev) => {
      const updatedc = [...prev];
      //    updatedc[index]={price_category:name , profit_percentage:Number(price),  }
      updatedc[index] = {
        price_category_id: id,
        profit_percentage: String(price),
        price_category: name,
      };
      return updatedc;
    });
  };
  const submit = () => {
    let data = localCat.map((c) => {
      if (c.profit_percentage === "") {
        c.profit_percentage = 0;
      }
      return c;
    });
    const fdata = data.map((ele) => {
      console.log(ele, "ooo");
      return {
        price_category_id: ele.price_category_id,
        profit_percentage: ele.profit_percentage,
      };
    });
    console.log(fdata, "ooo");
    let payload = {
      categories: fdata,
    };
    // postProductPriceRequest
    dispatch(postProductPriceRequest(payload));
    // dispatch(getProductPriceRequest());
  };
  useEffect(() => {
    dispatch(getProductPriceRequest());
  }, [dispatch]);
  
  useEffect(() => {
    if (postPriceSuccess) {
      dispatch(getProductPriceRequest()); 
    }
  }, [postPriceSuccess, dispatch]);
  
  useEffect(() => {
    if (cat && cat.length > 0) {
      const updatedCategories = cat.map((c) => ({
        price_category_id: c.id,
        profit_percentage: c.profit_percentage,
        price_category: c.price_category,
      }));
      setLocalCat(updatedCategories);
    }
  }, [cat]);
  
  console.log(localCat, "ggg");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div className="col-8 row">
            <h2 className="content-title">Profit Margin</h2>
            <p>Edit Profit Margin</p>
            </div>
            
          </div>
          <div className="card mb-4">
            <div className="card-header">
              <h4>Profit Margin</h4>
            </div>
            <div className="card-body row">
              <div className="col-6">
                <Table>
                  <thead>
                    <tr>
                      <td>Category</td>
                      <td>Profit Margin</td>
                    </tr>
                  </thead>
                  <tbody>
                    {localCat &&
                      localCat.map((c, index) => {
                        return (
                          <tr>
                            <td>
                              <label>{c.price_category}</label>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={c.profit_percentage}
                                onChange={(e) =>
                                  priceChamge(
                                    e.target.value,
                                    index,
                                    c.price_category,
                                    c.price_category_id
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <button className="btn btn-primary " onClick={submit}>
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ProfitMargin;
