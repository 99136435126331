import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";
import { API_BASE, assestURL } from "../../services/config";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Spinner,
} from "react-bootstrap";

// import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
// import Breadcrumb from "../src/Common/Breadcrumb";
import dayjs from "dayjs";

// import { API_STATUS } from "../../utils/constants";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
// import {
//   companySelector,
//   getCompanyDetails,
// } from "../../store/reducer/CompanyReducer";
// import { Box, Modal, Typography } from "@mui/material";
import { debounce } from "lodash";
import Header from "../Header";

import Sidebar from "../Sidebar";
import {
  getPurchaseOrderDataRequest,
  poStateReset,
  uploadPdfSendRequest,
} from "../../store/PurchaseOrder/po.action";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";
import { PurchaseReportPayload } from "../../utils/config";

// import {
//   purchaseInvoiceList,
//   purchaseSelector,
//   purchaseInvoiceDatas,
//   updatePurchaseInvoiceStatus,
//   clearPurchaseLoadings,
// } from "../../store/reducer/PurchaseReducer";
// import { usersSelector } from "../../store/reducer/UserReducer";
const PurchaseInvoice = () => {
  const dropdownValues = {
    0: {
      data: [
        { handlerName: "Proforma", label: "Set To Proforma" },
        { handlerName: "Invoice", label: "Set To Invoice" },
      ],
    },
    1: {
      data: [
        { handlerName: "Invoice", label: "Set To Invoice" },
        ,
        { handlerName: "Draft", label: "Set To Draft" },
      ],
    },
    2: {
      data: [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
  };
  const style = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 30,
    p: 4,
  };

  const infoStyle = {
    boxShadow: " 2px 4px 2px 4px #d6e6ff ",
    padding: "10px",
  };

  const WarningStyle = {
    boxShadow: " 2px 4px 2px 4px #ffd28e ",
    padding: "10px",
    marginTop: "16px",
  };

  const purchaseInvoiceListData = "";
  // const {
  //   purchaseInvoiceListData,
  //   purchaseInvoiceCardDatas,
  //   purchaseerrorMessage,
  //   updatePurchaseInvoiceStatusLoad,
  // } = useSelector(purchaseSelector);
  // const {selectScreenData,
  // } = useSelector(usersSelector);

  // console.log(purchaseerrorMessage, "6yuhjk");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("invoice_no");
  const [openDrawer, setOpenDrawer] = useState(false);
  let [activesortBy, setActiveSortBy] = useState("invoice_no");
  const [info, setInfo] = useState({ 0: false });
  let [page, setPage] = useState(1);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  let [searchQuery, setSearchQuery] = useState("");
  const [userActions, setUserActions] = useState({});
  console.log(userActions, "3456789");
  const [state, setState] = useState({ modal_fullscreen: false });
  let { invoiceData, uploadPdfSuccess } = useSelector(purchaseOrderSelector);
  console.log(invoiceData, "invoiceData");
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  // useEffect(() => {
  //   console.log(purchaseInvoiceListData, "purchaseInvoiceListData");
  // }, [purchaseInvoiceListData]);
  // const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [sel_id, setSelId] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setSelId(id);
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const tog_fullscreen = () => {
    navigate("add");
    dispatch(poStateReset());
  };

  // const purchaseInvoiceHit = () => {
  //   dispatch(
  //     purchaseInvoiceList({
  //       query: searchQuery,
  //       page: page + 1,
  //       limit: rowsPerPage,
  //     })
  //   );
  //   dispatch(purchaseInvoiceDatas({}));
  // };

  // useEffect(() => {
  //   purchaseInvoiceHit();
  // }, [searchQuery]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    // purchaseInvoiceHit();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // purchaseInvoiceHit();
  };

  const searchData = (e) => {
    searchQuery = e;
    setSearchQuery(e);
    setPage(0);
    // dispatch(searchInvoiceDetails({ e }));
  };
  /** Debounce For Search the Data */
  const debounceSearch = useCallback(debounce(searchData, 500), []);

  //pagenation
  const totalPages1 = Math.ceil(invoiceData?.rows?.length / rowsPerPage);
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSubmit = () => {
    let formData = {};
    formData.invoice_pdf = file;
    formData.id = sel_id;
    console.log(formData, "formDatasdd");
    dispatch(uploadPdfSendRequest(formData));
  };
  /** Sorting the Table */
  // const sortByKey = (key) => {
  //   sortBy = key;
  //   if (activesortBy == key) {
  //     if (order == "desc") {
  //       order = "asc";
  //     } else {
  //       order = "desc";
  //     }
  //     setOrder(order);
  //   } else {
  //     order = "desc";
  //     activesortBy = key;
  //     setActiveSortBy(key);
  //     setOrder(order);
  //   }
  //   setSortBy(key);
  //   dispatch(
  //     purchaseInvoiceList({
  //       query: searchQuery,
  //       page: page + 1,
  //       sortby: sortBy,
  //       limit: rowsPerPage,
  //       order: order,

  //     })
  //   );
  // };
  /** Initial Call Function */
  useEffect(() => {
    // purchaseInvoiceHit();
    let payload = PurchaseReportPayload(page, rowsPerPage, searchQuery);

    dispatch(getPurchaseOrderDataRequest(payload));
  }, []);

  const handleDropDown = (data, type) => {
    console.log(data, type, "345673248");
    let formData = {};
    formData.id = data.id;
    if (type == "Proforma") {
      formData.status = 1;
      // dispatch(updatePurchaseInvoiceStatus({ formData }));
    }
    if (type == "Draft") {
      formData.status = 0;
      // dispatch(updatePurchaseInvoiceStatus({ formData }));
    }
    if (type == "Invoice") {
      formData.status = 2;
      // dispatch(updatePurchaseInvoiceStatus({ formData }));
    }
  };
  const getUserActionKey = (handlerName) => {
    console.log(handlerName, 5678920675);
    switch (handlerName) {
      case "Proforma":
        return "Purchase Invoice-proforma";
      case "setDraft":
        return "Purchase Invoice-draft";
      case "Invoice":
        return "Purchase Invoice-invoice";

      default:
        return null;
    }
  };
  /** Message Updates */
  useEffect(() => {
    if (uploadPdfSuccess) {
      let payload = PurchaseReportPayload(page, rowsPerPage, searchQuery);
      dispatch(getPurchaseOrderDataRequest(payload));
      setShow(false);
      dispatch(poStateReset());
    }
  }, [uploadPdfSuccess]);
  // useEffect(() => {
  //   if (selectScreenData && selectScreenData.length > 0) {
  //     const value = selectScreenData[0]?.actions;
  //     setUserActions(value || {});
  //   }
  // }, [selectScreenData]);
  // useEffect(() => {
  // if (updatePurchaseInvoiceStatusLoad === API_STATUS.FULFILLED) {
  //   toastr.clear();
  //   toastr.success("Updated Successfully!");
  //   purchaseInvoiceHit();
  //   dispatch(clearPurchaseLoadings());
  // }

  //   if (purchaseerrorMessage) {
  //     toastr.clear();
  //     if (purchaseerrorMessage.message)
  //       toastr.error(purchaseerrorMessage.message);
  //     else if (purchaseerrorMessage.errors) {
  //       let data = "";
  //       purchaseerrorMessage?.errors.map((err) => {
  //         data += err.message + " ";
  //       });
  //       toastr.error(data);
  //     }
  //     purchaseInvoiceHit();
  //     dispatch(clearPurchaseLoadings());
  //   }
  // }, [purchaseerrorMessage, updatePurchaseInvoiceStatusLoad]);
  // useEffect(() => {
  //   console.log(purchaseInvoiceCardDatas, "purchaseInvoiceCardDatas");
  // }, [purchaseInvoiceCardDatas]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="page-content">
            <div className="d-flex justify-content-between ">
              <h2 className="content-title card-title">Purchase Invoice</h2>

              <button
                type="button"
                className="waves-effect waves-light btn btn-primary mb-2"
                onClick={tog_fullscreen}
              >
                Add
              </button>
            </div>
            {/* <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoices This Year
                      </p>
                      <h4 className="mb-0">
                         {purchaseInvoiceCardDatas?.invoice_this_year?.length} 
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoices This Month
                      </p>
                      <h4 className="mb-0">
                         {purchaseInvoiceCardDatas?.invoice_this_month?.length} 
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Amount to Pay
                      </p>
                      <h4 className="mb-0">
                         {purchaseInvoiceCardDatas?.amountData &&
                          purchaseInvoiceCardDatas?.amountData[0]?.bill_amount} 
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Balnce Amount
                      </p>
                      <h4 className="mb-0">
                        {purchaseInvoiceCardDatas?.amountData &&
                          purchaseInvoiceCardDatas?.amountData[0]
                            ?.invoice_current_balance} 
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>  */}
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-8">
                    <h5>
                      {" "}
                      <span>{}</span>
                    </h5>
                  </div>
                  <div className="col-md-4 row">
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      style={{ float: "right" }}
                      onChange={(e) => {
                        debounceSearch(e.target.value);
                      }}
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="table-responsive react-table">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} stickyHeader>
                      <TableHead className="table-light table-nowrap">
                        <TableRow>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "invoice_no" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("invoice_no");
                              }}
                            >
                              Invoice No
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={
                                sortBy == "invoice_due_date" ? true : false
                              }
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("invoice_due_date");
                              }}
                            >
                              Invoice Date
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            Company Name
                          </StyledTableCell>
                          {/* <StyledTableCell align="">
                            <TableSortLabel
                              active={
                                sortBy == "invoice_due_date" ? true : false
                              }
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("invoice_due_date");
                              }}
                            >
                              Invoice Due Date
                            </TableSortLabel>
                          </StyledTableCell> */}
                          {/* <StyledTableCell align="">
                          <TableSortLabel
                            active={
                              sortBy == "invoice_no" ? true : false
                            }
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("invoice_no");
                            }}
                          >
                            Vendor Invoice No
                          </TableSortLabel>
                        </StyledTableCell> */}
                          {/* <StyledTableCell align="">PO Number</StyledTableCell> */}
                          {/* <StyledTableCell align="">
                        <TableSortLabel
                            active={sortBy == "purchcase_date" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("purchcase_date");
                            }}
                          >
                          Vendor Invoice Date
                          </TableSortLabel>
                        </StyledTableCell> */}
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "net_amoount" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("net_amoount");
                              }}
                            >
                              Invoice Amount
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "net_amoount" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("net_amoount");
                              }}
                            >
                              Payment Status
                            </TableSortLabel>
                          </StyledTableCell>
                          {/* <StyledTableCell align="">
                            <TableSortLabel
                              active={
                                sortBy == "invoice_current_balance"
                                  ? true
                                  : false
                              }
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("invoice_current_balance");
                              }}
                            >
                              Balance
                            </TableSortLabel>
                          </StyledTableCell> */}
                          {/* <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "due_days" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("due_days");
                              }}
                            >
                              Due days
                            </TableSortLabel>
                          </StyledTableCell> */}
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "notes" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("notes");
                              }}
                            >
                              Invoice PDF
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "notes" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("notes");
                              }}
                            >
                              Upload Invoice PDF
                            </TableSortLabel>
                          </StyledTableCell>
                          {/* <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "status" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                // sortByKey("status");
                              }}
                            >
                              Status
                            </TableSortLabel>
                          </StyledTableCell> */}
                          <StyledTableCell align="">Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData &&
                          invoiceData?.rows?.map((row, index) => (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {row?.invoice_no}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {dayjs(row?.purchase_date).format("DD-MM-YYYY")}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.purchasecompany?.company_name}
                              </StyledTableCell>
                              {/* <StyledTableCell align="">
                                {row?.invoice_due_date &&
                                  dayjs(row?.invoice_due_date).format(
                                    "DD-MM-YYYY"
                                  )}
                              </StyledTableCell> */}
                              {/* <StyledTableCell align="">
                              {row?.invoice_no}
                            </StyledTableCell> */}
                              {/* <StyledTableCell align="">
                              {row?.purchase_order?.quotation_no}
                            </StyledTableCell> */}
                              {/* <StyledTableCell align="">
                              {dayjs(row?.invoice_due_date).format(
                                "DD-MM-YYYY"
                              )}
                            </StyledTableCell> */}
                              <StyledTableCell align="">
                                {row?.bill_amount}
                              </StyledTableCell>
                              {/* <StyledTableCell align="">
                                {row?.invoice_current_balance}
                              </StyledTableCell> */}
                              <StyledTableCell align="">
                                {
                                  <span
                                    className={`badge rounded-pill ${
                                      row?.paid_status === 0
                                        ? "alert-danger"
                                        : row?.paid_status === 1
                                        ? "alert-success"
                                        : "alert-warning"
                                    }`}
                                  >
                                    {row?.paid_status === 0
                                      ? "Unpaid"
                                      : row?.paid_status === 1
                                      ? "Paid"
                                      : "Partially Paid"}
                                  </span>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <a
                                  className="text-success"
                                  href={API_BASE + `${row?.invoice_pdf}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  {row?.invoice_pdf == null ||
                                  row?.invoice_pdf == ""
                                    ? "-"
                                    : "View Pdf"}
                                </a>
                              </StyledTableCell>

                              <StyledTableCell align="">
                                {/* {row?.notes} */}
                                <button
                                  className="btn btn-md rounded font-sm"
                                  onClick={() => {
                                    handleShow(row?.id);
                                  }}
                                >
                                  Upload PDF
                                </button>
                              </StyledTableCell>
                              {/* <StyledTableCell align="">
                                {row.status === 0
                                  ? "Draft"
                                  : row.status === 1
                                  ? "Proforma"
                                  : "Invoice"}
                              </StyledTableCell> */}
                              <StyledTableCell
                                align=""
                                className="button-items"
                              >
                                <button
                                  className="btn btn-md rounded font-sm"
                                  onClick={() => {
                                    navigate(
                                      `/purchanseInovice/edit/${row?.id}`
                                    );
                                    dispatch(poStateReset());
                                  }}
                                >
                                  {dayjs(row?.created_at).format(
                                    "YYYY-MM-DD"
                                  ) == dayjs().format("YYYY-MM-DD")
                                    ? "View /  Edit"
                                    : "View"}
                                </button>
                                {/* <div className="dropdown">
                                  <a
                                    className="btn btn-light rounded btn-sm font-sm"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                  >
                                    <i className="material-icons md-more_horiz"></i>
                                  </a>
                                  <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#">
                                      View detail
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      Edit info
                                    </a>
                                    <a
                                      className="dropdown-item text-danger"
                                      href="#"
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div> */}
                                {/* <div className="d-flex">
                                <a
                                  href="javascript:void(0);"
                                  title="Edit"
                                  className="btn btn-primary dropdown-item  "
                                  onClick={() => {
                                    navigate(`edit/${row.id}`);
                                  }}
                                >
                                  <i className=" ri-ball-pen-line"></i>
                                </a>
                                {row.status != 2 ? (
                                  <>
                                    <Dropdown
                                      isOpen={info[index]}
                                      direction="left"
                                      toggle={() => {
                                        info[index] = !info[index];
                                        setInfo({ ...info });
                                      }}
                                    >
                                      <DropdownToggle color="danger" caret>
                                        <i class="ri-more-2-line"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {dropdownValues[row.status].data.map(
                                          (items) => {
                                            return (
                                              <DropdownItem
                                                onClick={() =>
                                                  handleDropDown(
                                                    row,
                                                    items.handlerName
                                                  )
                                                }
                                              >
                                                {items.label}
                                              </DropdownItem>
                                            );
                                          }
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                    
                                        <Dropdown
                                          isOpen={info[index]}
                                          direction="left"
                                          toggle={() => {
                                            info[index] = !info[index];
                                            setInfo({ ...info });
                                          }}
                                        >
                                          <DropdownToggle color="danger" caret>
                                            <i class="ri-more-2-line"></i>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {dropdownValues[
                                              row.status
                                            ].data.map((items) => {
                                              const key = getUserActionKey(
                                                items.handlerName
                                              );
                                              if (userActions[key]) {
                                                return (
                                                  <DropdownItem
                                                    onClick={() =>
                                                      handleDropDown(
                                                        row,
                                                        items.handlerName
                                                      )
                                                    }
                                                  >
                                                    {items.label}
                                                  </DropdownItem>
                                                );
                                              }
                                            })}
                                          </DropdownMenu>
                                        </Dropdown>
                                      
                                  </>
                                ) : (
                                  <>
                                    <div
                                        className="btn btn-success"
                                        title="View"
                                        // onClick={() => handleZatcaView(row)}
                                      >
                                        <i class="ri-eye-line"></i>
                                      </div>
                                    <Row className="w-100 ">
                                      <Col md="6">
                                        <div className="text-end">
                                          <h3>-</h3>
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </div> */}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                      {/* <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={12}
                          count={purchaseInvoiceListData?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter> */}
                    </Table>
                  </TableContainer>
                </div>
              </CardBody>
            </Card>
            <div className="pagination-area mt-15 mb-50">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="material-icons md-chevron_left"></i>
                    </a>
                  </li>

                  {pagesToDisplay.map((page) => {
                    return page != "..." ? (
                      <li
                        key={page}
                        className={`page-item b ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ) : page == "..." ? (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <a className="page-link" href="#">
                          {page}
                        </a>
                      </li>
                    ) : (
                      ""
                    );
                  })}

                  <li
                    className={`page-item ${
                      currentPage === totalPages1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="material-icons md-chevron_right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Invoice Pdf</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column ">
                <label className="mb-2">Upload Here</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="application/pdf"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </main>
    </>
  );
};
export default PurchaseInvoice;
