import React, { useEffect } from "react";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { creditDueDataRequest } from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";

export const OrderSalesReminder = () => {
  const dispatch = useDispatch();
  const { creditDueData } = useSelector(orderSelector);
  console.log(creditDueData, "creditDueData");
  /* useEffect */
  useEffect(() => {
    dispatch(creditDueDataRequest({}));
  }, []);
  return (
    <div>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Order Reminder</h2>
              <p>Important insights of your business here</p>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <h4 className="card-title">Due Days Exceeded List</h4>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle" scope="col">
                          Vendor ID
                        </th>
                        <th className="align-middle" scope="col">
                          Vendor Name
                        </th>
                        <th className="align-middle" scope="col">
                          Due Days
                        </th>
                        <th className="align-middle" scope="col">
                          Order ID
                        </th>
                        <th className="align-middle" scope="col">
                          Due Amt
                        </th>
                        <th className="align-middle" scope="col">
                          Order Date
                        </th>
                        <th className="align-middle" scope="col">
                          Over Due <br />
                          Exceed Days
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditDueData?.map((ele, indx) =>
                        ele?.orders?.map((ele1) => (
                          <tr key={`${indx}-${ele1?.user?.id}`}>
                            <td>
                              <a className="fw-bold" href="#">
                                {ele1?.user?.id}
                              </a>
                            </td>
                            <td> {ele1?.user?.name}</td>
                            <td>{ele1?.user?.duedays_limit}</td>
                            <td>{ele1?.orderId}</td>
                            <td>{ele1?.subTotal}</td>
                            <td>
                              {dayjs(ele1?.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>{ele1?.daysExceeded}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <h4 className="card-title">Credit Limit Exceeded List</h4>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle" scope="col">
                          Vendor ID
                        </th>
                        <th className="align-middle" scope="col">
                          Vendor Name
                        </th>

                        <th className="align-middle" scope="col">
                          Credit Limit
                        </th>

                        <th className="align-middle" scope="col">
                          Invoice Total <br />
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditDueData
                        .filter(
                          (ele) => ele?.totalUnpaidAmount > ele?.creditLimit
                        )
                        ?.map((ele, indx) => {
                          return (
                            <tr>
                              <td>
                                <a className="fw-bold" href="#">
                                  {ele?.userId}
                                </a>
                              </td>
                              <td> {ele?.name}</td>
                              <td>
                                {" "}
                                {ele?.creditLimit ? ele?.creditLimit : "0"}
                              </td>
                              <td>{ele?.totalUnpaidAmount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
