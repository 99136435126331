import { decryptdata } from "../../utils/encrypt&decrypt";
import {
  purchaseCompanyActionTypes,
  purchaseCompanyInitialState,
} from "./pc.model";

export const reducer = (state = purchaseCompanyInitialState, action) => {
  switch (action.type) {
    case purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_REQUEST:
      return {
        ...state,
        Loading: true,
      };
    case purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_SUCCESS:
      // console.log(action?.payload?.data?.datas, process.env.REACT_APP_SECRETKEY, "jkmls");
      // console.log(decryptdata(
      //   action?.payload?.data?.datas,
      //   "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
      // ),"jkmls")
     try{
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      
      console.log(payloaddatas1.data,"jkmls");
      
      return {
        ...state,
        Loading: false,
        companyData: payloaddatas1,
      };
     }catch(e){
      console.log(e,"ii")
     }
      
    case purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_FAILURE:
      return {
        ...state,
        Loading: false,
      };

    case purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_REQUEST:
      return {
        ...state,
        postPCDataLoad: true,
        postPCDataSuccess: false,
      };
    case purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_SUCCESS:
      return {
        ...state,
        postPCDataLoad: false,
        postPCDataSuccess: true,
      };
    case purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_FAILURE:
      return {
        ...state,
        postPCDataLoad: false,
        postPCDataSuccess: false,
      };
    case purchaseCompanyActionTypes.UPDATEPURCHASECOMPANYDATA_REQUEST:
      return {
        ...state,
        updatePCdataLoad: true,
        updatePCdataSuccess: false,
      };
    case purchaseCompanyActionTypes.UPDATEPURCHASECOMPANYDATA_SUCCESS:
      return {
        ...state,
        updatePCdataLoad: false,
        updatePCdataSuccess: true,
      };
    case purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_FAILURE:
      return {
        ...state,
        updatePCdataLoad: false,
        updatePCdataSuccess: false,
      };
    case purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_REQUEST:
      return {
        ...state,
        deletePCdataLoad: true,
        deletePCDataSuccess: false,
      };
    case purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_SUCCESS:
      return {
        ...state,
        deletePCdataLoad: false,
        deletePCDataSuccess: true,
      };
    case purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_FAILURE:
      return {
        ...state,
        deletePCdataLoad: false,
        deletePCDataSuccess: false,
      };
    case purchaseCompanyActionTypes.PCSTATERESET_REQUEST:
      return {
        ...state,
        Loading: false,
        companyData: [],
        postPCDataLoad: false,
        postPCDataSuccess: false,
        updatePCdataLoad: false,
        updatePCdataSuccess: false,
        deletePCdataLoad: false,
        deletePCDataSuccess: false,
      };
    default:
      return state;
  }
};

export { reducer as PurchaseCustomerReducer };
