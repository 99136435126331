import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getTockensApi = () => {
 
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +'/support' ,Http.getAuthToken() );
};
export const postTockenApi = (formData) => {
  console.log(formData,"yyy");
  
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/support",formData,Http.getAuthToken());
};
export const updateTockenApi = (formData) => {
  console.log(formData,"yyy");
  
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/support/status",formData,Http.getAuthToken());
};
