import { decryptdata } from "../../utils/encrypt&decrypt";
import { UserActionTypes, userInitialState } from "./user.model";
import _ from "underscore";

const reducer = (state = userInitialState, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_TOKEN_REQUEST:
    case UserActionTypes.UPDATE_SETTINGS_REQUEST:
    case UserActionTypes.VERIFY_PIN_REQUEST:
    case UserActionTypes.GET_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UserActionTypes.UPDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.VERIFY_PIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.GET_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: action.payload,
      };

    case UserActionTypes.FETCH_BALANCE_REQUEST: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case UserActionTypes.FETCH_BALANCE_SUCCESS: {
      let resp = _.uniq(action.response);
      return Object.assign({}, state, {
        isLoading: false,
        coinBalance: resp,
      });
    }
    case UserActionTypes.GET_USERSOCIAL_REQUEST: {
      return {
        ...state,
        userSocialLoader: true,
      };
    }
    case UserActionTypes.GET_USERSOCIAL_SUCCESS:
      var { payload } = action;
      console.log(payload, "get users social success userAuthedSocials");
      return {
        ...state,
        userAuthedSocials: payload?.data?.data,
        userSocialLoader: false,
      };
    case UserActionTypes.GET_USERSOCIAL_FAILURE:
      var { payload } = action;
      console.log(payload, "get user social failure");
      return {
        ...state,
        userSocialLoader: false,
      };

    case UserActionTypes.UPDATE_TOKEN_FAILURE:
    case UserActionTypes.UPDATE_SETTINGS_FAILURE:
    case UserActionTypes.VERIFY_PIN_FAILURE:
    case UserActionTypes.GET_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UserActionTypes.GETUSERDATA_REQUEST:
      return {
        ...state,
        userDataLoading: true,
      };
    case UserActionTypes.GETUSERDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas2 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas2.data, "yhoio");
      return {
        ...state,
        userDataLoading: false,
        userData: payloaddatas2,
      };
    case UserActionTypes.GETUSERDATA_FAILURE:
      return {
        ...state,
        userDataLoading: false,
      };
    case UserActionTypes.CREATEUSER_REQUEST:
      return {
        ...state,
        createUserLoad: true,
        createSuccess: false,
      };
    case UserActionTypes.CREATEUSER_SUCCESS:
      return {
        ...state,
        createUserLoad: false,
        createSuccess: true,
      };
    case UserActionTypes.CREATEUSER_FAILURE:
      return {
        ...state,
        createUserLoad: false,
        createSuccess: false,
      };
    case UserActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserLoad: true,
        updateSuccess: false,
      };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoad: false,
        updateSuccess: true,
      };
    case UserActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserLoad: false,
        updateSuccess: false,
      };
    case UserActionTypes.DELETEUSERDATA_REQUEST:
      return {
        ...state,
        deleteLoad: true,
        deleteLoadSuccess: false,
      };
    case UserActionTypes.DELETEUSERDATA_SUCCESS:
      return {
        ...state,
        deleteLoad: false,
        deleteLoadSuccess: true,
      };
    case UserActionTypes.DELETEUSERDATA_FAILURE:
      return {
        ...state,
        deleteLoad: false,
        deleteLoadSuccess: false,
      };

    case UserActionTypes.USER_LOGOUT:
      return {
        ...state,
        createUserLoad: false,
        createSuccess: false,
        updateUserLoad: false,
        updateSuccess: false,
        userData: [],
        deleteLoad: false,
        deleteLoadSuccess: false,
      };
    default:
      return state;
  }
};

export { reducer as UserReducer };
