import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
import {
  dueDayDataRequest,
  getCardDataRequest,
} from "../../store/dashboard/dashboard.action";
import { dashboardSelector } from "../../store/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { orderSelector } from "../../store/order/order.model";
import { creditDueDataRequest } from "../../store/order/order.action";
function Home() {
  const dispatch = useDispatch();

  const { cardData, dueDayData } = useSelector(dashboardSelector);
  const { creditDueData } = useSelector(orderSelector);

  console.log(dueDayData, "6789");
  console.log(creditDueData, "creditDueData");
  /* useEffect */
  useEffect(() => {}, []);
  useEffect(() => {
    dispatch(getCardDataRequest());
    // dispatch(dueDayDataRequest({}));
    dispatch(creditDueDataRequest({}));
  }, []);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Dashboard</h2>
              <p>Whole data about your business here</p>
            </div>
            {/* <div>
              <a className="btn btn-primary" href="#">
                <i className="text-muted material-icons md-post_add"></i>Create
                report
              </a>
            </div> */}
          </div>
          <Row>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products</h6>
                    <span>{cardData?.total_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories and{" "}
                      {cardData?.total_brands} brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Total</h6>
                    <span>{cardData?.total_orders}</span>
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                    {/* <span className="text-sm">
                      Shipping fees are not included
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    {/* <i className="text-success material-icons md-local_shipping"></i> */}
                    <i className="text-warning material-icons md-monetization_on"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Total</h6>
                    <span>
                      ₹{parseFloat(cardData?.total_revenue).toFixed(2)}
                    </span>
                    {/* <span className="text-sm">Excluding orders in transit</span> */}
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit %</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">New Products added</h6>
                    <span>{cardData?.new_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories and{" "}
                      {cardData?.total_brands} brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Orders Received This Month
                    </h6>
                    <span>{cardData?.monthly_orders}</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Revenue Received This Month
                    </h6>
                    <span>
                      ₹{parseFloat(cardData?.monthly_revenue).toFixed(2)}
                    </span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % this Month</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products out of stock</h6>
                    <span>{cardData?.out_of_stock_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories and{" "}
                      {cardData?.total_brands} brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Received Today</h6>
                    <span>{cardData?.today_orders}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Received Today</h6>
                    <span>
                      ₹{parseFloat(cardData?.today_revenue).toFixed(2)}
                    </span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % Today</h6>
                    <span>{cardData?.today_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Products out of stock Soon
                    </h6>
                    <span>{cardData?.out_of_stock_soon}</span>
                    <span className="text-sm">
                      in 8 categories and 4 brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Orders Forecast This month
                    </h6>
                    <span>{cardData?.orders_forecast}</span>
                    <span className="text-sm">
                      Increase by 15% from last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Revenue Forecast this month
                    </h6>
                    <span>
                      ₹{parseFloat(cardData?.revenue_forecast).toFixed(2)}
                    </span>
                    <span className="text-sm">
                      Increase by 15% from last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Profit % Forecast this month
                    </h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Top Brands this month</h5>
                  <span className="text-muted font-xs">Wipro</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "15%" }}
                    >
                      15%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "65%" }}
                    >
                      65%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Finolex</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "51%" }}
                    >
                      {" "}
                      51%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Anchor</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Legrand</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                </article>
              </div>
            </Col>
            <Col xl={4} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Fast Selling Products</h5>
                  <span className="text-muted font-xs">Finolex wires</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "15%" }}
                    >
                      15%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM switches</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "65%" }}
                    >
                      65%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM Adapter</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "51%" }}
                    >
                      {" "}
                      51%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM Panels</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Anchor Panels</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                </article>
              </div>
            </Col>
            <Col xl={4} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Slow selling Products</h5>
                  <span className="text-muted font-xs">Anchor Switch</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "15%" }}
                    >
                      15%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Anchor Socket</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "65%" }}
                    >
                      65%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Legrand Panel</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "51%" }}
                    >
                      {" "}
                      51%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Legrand Wires</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Finolex Switches</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <div className="card mb-4">
          <header className="card-header">
            <h4 className="card-title">Credit Limit Exceeded List</h4>
          </header>
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="align-middle" scope="col">
                        Vendor Name
                      </th>

                      <th className="align-middle" scope="col">
                        Credit Limit
                      </th>

                      <th className="align-middle" scope="col">
                      Invoice Total   <br />    
                      Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditDueData
                      ?.filter(
                        (ele) => ele?.totalUnpaidAmount > ele?.creditLimit
                      )
                      ?.map((ele, indx) => {
                        return (
                          <tr>
                            <td> {ele?.name}</td>
                            <td>
                              {ele?.creditLimit ? ele?.creditLimit : "0"}
                            </td>
                            <td>{ele?.totalUnpaidAmount}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
          <div className="card mb-4">
          <header className="card-header">
            <h4 className="card-title">Due Days Exceeded List</h4>
          </header>
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                    
                      <th className="align-middle" scope="col">
                        Vendor Name
                      </th>
                      <th className="align-middle" scope="col">
                        Due Days
                      </th>
                      <th className="align-middle" scope="col">
                        Order ID
                      </th>
                      <th className="align-middle" scope="col">
                        Due Amt
                      </th>
                      <th className="align-middle" scope="col">
                        Over Due <br />
                        Exceed Days
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditDueData?.map((ele, indx) =>
                      ele?.orders?.map((ele1) => (
                        <tr key={`${indx}-${ele1?.user?.id}`}>
                          <td> {ele1?.user?.name}</td>
                          <td>{ele1?.user?.duedays_limit}</td>
                          <td>{ele1?.orderId}</td>
                          <td>{ele1?.subTotal}</td>
                          <td>{ele1?.daysExceeded}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       
          <div className="pagination-area mt-30 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    16
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Home;
