import { action } from "typesafe-actions";
import { productActionTypes } from "./product.model";

export const postProductRequest = (req) =>
  action(productActionTypes.POSTPRODUCT_REQUEST, req);
export const postProductSuccess = (res) =>
  action(productActionTypes.POSTPRODUCT_SUCCESS, res);
export const postProductFailure = (err) =>
  action(productActionTypes.POSTPRODUCT_FAILURE, err);

export const getProductRequest = (req) =>
  action(productActionTypes.GETPRODUCT_REQUEST, req);
export const getProductSuccess = (res) =>
  action(productActionTypes.GETPRODUCT_SUCCESS, res);
export const getProductFailure = (err) =>
  action(productActionTypes.GETPRODUCT_FAILURE, err);

export const UpdateProductRequest = (req) =>
  action(productActionTypes.UPDATEPRODUCT_REQUEST, req);
export const UpdateProductSuccess = (res) =>
  action(productActionTypes.UPDATEPRODUCT_SUCCESS, res);
export const UpdateProductFailure = (err) =>
  action(productActionTypes.UPDATEPRODUCT_FAILURE, err);

export const deleteProductRequest = (req) =>
  action(productActionTypes.DELETEPRODUCT_REQUEST, req);
export const deleteProductSuccess = (res) =>
  action(productActionTypes.DELETEPRODUCT_SUCCESS, res);
export const deleteProductFailure = (err) =>
  action(productActionTypes.DELETEPRODUCT_FAILURE, err);

export const postProductPriceRequest = (req) =>
  action(productActionTypes.POSTPRODUCTPRICE_REQUEST, req);
export const postProductPriceSuccess = (res) =>
  action(productActionTypes.POSTPRODUCTPRICE_SUCCESS, res);
export const postProductPriceFailure = (err) =>
  action(productActionTypes.POSTPRODUCTPRICE_FAILURE, err);

export const getProductPriceRequest = (req) =>
  action(productActionTypes.GETPRODUCTCAT_REQUEST, req);
export const getProductPriceSuccess = (res) =>
  action(productActionTypes.GETPRODUCTCAT_SUCCESS, res);
export const getProductPriceFailure = (err) =>
  action(productActionTypes.GETPRODUCTCAT_FAILURE, err);

// export const getAllProductPriceRequest = (req) =>
//   action(productActionTypes.GETALLPRODUCTCAT_REQUEST, req);
// export const getAllProductPriceSuccess = (res) =>
//   action(productActionTypes.GETALLPRODUCTCAT_SUCCESS, res);
// export const getAllProductPriceFailure = (err) =>
//   action(productActionTypes.GETALLPRODUCTCAT_FAILURE, err);

export const productStateResetRequest=(req)=>
    action(productActionTypes.STATE_RESET_REQUEST, req);

export const postBulkProductRequest = (req) =>
  action(productActionTypes.POSTBULKPRODUCT_REQUEST, req);
export const postBulkProductSuccess = (res) =>
  action(productActionTypes.POSTBULKPRODUCT_SUCCESS, res);
export const postBulkProductFailure = (err) =>
  action(productActionTypes.POSTBULKPRODUCT_FAILURE, err);
