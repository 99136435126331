import { call, put, takeEvery } from "redux-saga/effects";
import * as orderAction from "./order.action";
import { orderActionTypes } from "./order.model";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import {
  checkOutApi,
  crdDueDataApi,
  createPayment,
  getPayments,
  getpaymentStat,
  getUserByidDetailApi,
  getUserOrderDataApi,
  orderDetailDataApi,
  orderStatusUpdateApi,
  pdfApi,
  postOrderDispatchApi,
  srdDueDataApi,
  updateTprice,
  weeklypayApi,
} from "./order.api";

function* handlegetUserOrderData(action) {
  try {
    const response = yield call(getUserOrderDataApi, action.payload);
    yield put(orderAction.getUserOrderDataSuccess(response));
  } catch (e) {
    yield put(orderAction.getUserOrderDataFailure(e));
  }
}
function* handlegetAllUserOrderData(action) {
  try {
    const response = yield call(getUserOrderDataApi, action.payload);
    yield put(orderAction.getAllUserOrderDataSuccess(response));
  } catch (e) {
    yield put(orderAction.getAllUserOrderDataFailure(e));
  }
}
function* handleOrderDispatch(action) {
  try {
    const response = yield call(postOrderDispatchApi, action.payload);
    yield put(orderAction.orderDispatchSuccess(response));
  } catch (e) {
    yield put(orderAction.orderDispatchFailure(e));
  }
}

function* handleGetOrderDetailByID(action) {
  try {
    const response = yield call(orderDetailDataApi, action.payload);
    yield put(orderAction.orderDetailGetSuccess(response));
  } catch (e) {
    yield put(orderAction.orderDetailGetFailure(e));
  }
}
function* handleOrderStatusChange(action) {
  try {
    const response = yield call(orderStatusUpdateApi, action.payload);
    yield put(orderAction.UpdateOrderStatusSuccess(response));
  } catch (e) {
    yield put(orderAction.UpdateOrderStatusFailure(e));
  }
}
function* handleCreditDue(action) {
  try {
    const response = yield call(crdDueDataApi, action.payload);
    yield put(orderAction.creditDueDataSuccess(response));
  } catch (e) {
    yield put(orderAction.creditDueDataFailure(e));
  }
}
function* handleSreditDue(action) {
  try {
    const response = yield call(srdDueDataApi, action.payload);
    yield put(orderAction.sreditDueDataSuccess(response));
  } catch (e) {
    yield put(orderAction.sreditDueDataFailure(e));
  }
}

function* handleGeneratePdf(action) {
  try {
    const response = yield call(pdfApi, action.payload);
    yield put(orderAction.generatePDFSuccess(response));
    toast.dismiss();
    toast.success("PDF opened in new tab");
  } catch (e) {
    yield put(orderAction.generatePDFFailure(e));
  }
}

export function* handleCheckout(action) {
  try {
    const response = yield call(checkOutApi, action.payload);
    yield put(orderAction.checkOutDataSuccess(response));
  } catch (err) {
    yield put(orderAction.checkOutDataFailure(err));
  }
}

export function* getUserIdOrderData(action) {
  try {
    const response = yield call(getUserByidDetailApi, action.payload);
    yield put(orderAction.getUserOrderDetailByIdSuccess(response));
  } catch (err) {
    yield put(orderAction.getUserOrderDetailByIdFailure(err));
  }
}
export function* handleCreatePayment(action) {
  try {
    const response = yield call(createPayment, action.payload);
    yield put(orderAction.postPaymentDataSuccess(response));
  } catch (err) {
    yield put(orderAction.postPaymentDataFailure(err));
  }
}

export function* handleGetPayment(action) {
  try {
    const response = yield call(getPayments, action.payload);
    yield put(orderAction.getPaymentSuccess(response));
  } catch (err) {
    yield put(orderAction.getPaymentFailure(err));
  }
}
export function* handlegetStatPayment(action) {
  try {
    const response = yield call(getpaymentStat, action.payload);
    yield put(orderAction.getStatPaymentDataSuccess(response));
  } catch (err) {
    yield put(orderAction.getStatPaymentDataFailure(err));
  }
}
export function* handleupdateTprice(action) {
  try {
    const response = yield call(updateTprice, action.payload);
    yield put(orderAction.updateTpriceSuccess(response));
    Swal.fire({
      title: "Success",
      text: "Transport Charges Added Succesfully!",
      icon: "success",
    });
  } catch (err) {
    yield put(orderAction.updateTpriceFailure(err));
    Swal.fire({
      title: "Error",
      text: err?.response?.data?.message,
      icon: "error",
    });
  }
}
export function* handleWeeklyReminder(action) {
  try {
    const response = yield call(weeklypayApi, action.payload);
    yield put(orderAction.weeklyPaymentReminderDataSuccess(response));
  } catch (err) {
    yield put(orderAction.weeklyPaymentReminderDataFailure(err));
  }
}
export function* orderSaga() {
  yield takeEvery(
    orderActionTypes.GETUSERORDER_REQUEST,
    handlegetUserOrderData
  );
  yield takeEvery(
    orderActionTypes.GETALLUSERORDER_REQUEST,
    handlegetAllUserOrderData
  );
  yield takeEvery(orderActionTypes.ORDERDISPATCH_REQUEST, handleOrderDispatch);
  yield takeEvery(
    orderActionTypes.ORDERDETAILBYID_REQUEST,
    handleGetOrderDetailByID
  );
  yield takeEvery(
    orderActionTypes.ORDERDSTATUSUPDADE_REQUEST,
    handleOrderStatusChange
  );
  yield takeEvery(orderActionTypes.CREDITDUEPAYDATA_REQUEST, handleCreditDue);
  yield takeEvery(orderActionTypes.SREDITDUEPAYDATA_REQUEST, handleSreditDue);
  yield takeEvery(orderActionTypes.GENERATE_REQUEST, handleGeneratePdf);
  yield takeEvery(orderActionTypes.CHECKOUT_POST_REQUEST, handleCheckout);
  yield takeEvery(
    orderActionTypes.GETORDERDATABYID_REQUEST,
    getUserIdOrderData
  );
  yield takeEvery(
    orderActionTypes.ORDERPAYMENTSEND_REQUEST,
    handleCreatePayment
  );
  yield takeEvery(orderActionTypes.GETPAYMENTLIST_REQUEST, handleGetPayment);
  yield takeEvery(
    orderActionTypes.GETPAYMENTSTATDATA_REQUEST,
    handlegetStatPayment
  );
  yield takeEvery(
    orderActionTypes.UPDATETRANSPORTPRICE_REQUEST,
    handleupdateTprice
  );
  yield takeEvery(
    orderActionTypes.WEEKLYPAYDATAGET_REQUEST,
    handleWeeklyReminder
  );
}
