import { dashboardActionTypes, dashboardInitialState } from "./dashboard.model";

const reducer = (state = dashboardInitialState, action) => {
  switch (action.type) {
    case dashboardActionTypes.GETCARDDATA_REQUEST:
      return {
        ...state,
        cardLoad: true,
      };
    case dashboardActionTypes.GETCARDDATA_SUCCESS:
      return {
        ...state,
        cardLoad: false,
        cardData: action.payload?.data?.data,
      };
    case dashboardActionTypes.GETCARDDATA_FAILURE:
      return {
        ...state,
        cardLoad: false,
      };
    case dashboardActionTypes.GETDASHBOARDDATA_REQUEST:
      return {
        ...state,
        dashLoad: true,
      };
    case dashboardActionTypes.GETDASHBOARDDATA_SUCCESS:
      return {
        ...state,
        dashLoad: false,
        dashData: action.payload?.data?.data,
      };
    case dashboardActionTypes.GETDASHBOARDDATA_FAILURE:
      return {
        ...state,
        dashLoad: false,
      };
    case dashboardActionTypes.PAYMENTDUEDAYS_REQUEST:
      return {
        ...state,
        dueLoad: true,
      };
    case dashboardActionTypes.PAYMENTDUEDAYS_SUCCESS:
      return {
        ...state,
        dueLoad: true,
        dueDayData: action.payload?.data?.data,
      };
    case dashboardActionTypes.PAYMENTDUEDAYS_FAILURE:
      return {
        ...state,
        dueLoad: true,
      };

    case dashboardActionTypes.GETORDERCARDDATA_REQUEST:
      return {
        ...state,
        ordercardLoad: true,
      };
    case dashboardActionTypes.GETORDERCARDDATA_SUCCESS:
      console.log(action.payload.data.stats, "xoxo");
      return {
        ...state,

        ordercardLoad: false,
        ordercardData: action.payload?.data?.stats,
      };
    case dashboardActionTypes.GETORDERCARDDATA_FAILURE:
      return {
        ...state,
        ordercardLoad: false,
      };

    case dashboardActionTypes.GETPURCHASECARDDATA_REQUEST:
      return {
        ...state,
        purchasecardLoad: true,
      };
    case dashboardActionTypes.GETPURCHASECARDDATA_SUCCESS:
      console.log(action.payload.data.stats, "xoxo");

      return {
        ...state,
        purchasecardLoad: false,
        purchasecardData: action.payload?.data?.stats,
      };
    case dashboardActionTypes.GETPURCHASECARDDATA_FAILURE:
      return {
        ...state,
        purchasecardLoad: false,
      };

    case dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_REQUEST:
      return {
        ...state,
        purchasePaymentcardLoad: true,
      };
    case dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_SUCCESS:
      console.log(action.payload.data, "xoxo");

      return {
        ...state,

        purchasePaymentcardLoad: false,
        purchasePaymentcardData: action.payload?.data,
      };
    case dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_FAILURE:
      return {
        ...state,
        purchasePaymentcardLoad: false,
      };

    default:
      return state;
  }
};

export { reducer as dashboardReducer };
