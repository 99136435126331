import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

//
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "react-bootstrap";
//Import Breadcrumb
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import dayjs from "dayjs";

// import { paymentType } from "../../utils/constants";
import { debounce } from "lodash";
import { paymentType, paymentTypewithcheck } from "../../utils/Constants";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  getPaymentRequest,
  getStatPaymentDataRequest,
  orderStateResetRequest,
} from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";

const OrderPaymentList = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("created_at");
  let [activesortBy, setActiveSortBy] = useState("created_at");
  let [page, setPage] = useState(1);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(),
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [monthTotal, setMonthTotal] = useState(0);
  const [totToday, settotToday] = useState(0);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const [userActions, setUserActions] = useState({});

  //   const { payments, statData } = useSelector(salesinvoiceSelector);
  //   const { customerDatas } = useSelector(customersSelector);
  //   const { selectScreenData } = useSelector(usersSelector);
  const { getPaymentList, getPaymentStatData } = useSelector(orderSelector);
  const payments = [{}];
  const navigate = useNavigate();
  console.log(getPaymentList, getPaymentStatData, "paymesdljfld");

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    // dispatch(
    //     getPaymentList({
    //       query: searchQuery,
    //       page: page + 1,
    //       limit: rowsPerPage,
    //       sortby: sortBy,
    //       order: order,
    //     })
    //   );
  };

  useEffect(() => {
    let sum = 0;
    getPaymentStatData?.stat_month?.rows?.forEach((num) => {
      sum += parseFloat(num.pay_amt);
    });
    let tsum = 0;
    getPaymentStatData?.stat_today?.rows?.forEach((num) => {
      tsum += parseFloat(num.pay_amt);
    });
    setMonthTotal(sum);
    settotToday(tsum);
  }, [getPaymentStatData]);

  const tog_fullscreen = () => {
    navigate("/payment/orderpaymentlist/add");
    dispatch(orderStateResetRequest());
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // dispatch(
    //     getPaymentList({
    //       query: searchQuery,
    //       page: page + 1,
    //       limit: rowsPerPage,
    //       sortby: sortBy,
    //       order: order,
    //     })
    //   );
  };
  const searchData = (e) => {
    // searchQuery = e;
    setSearchQuery(e);
    setPage(0);
  };
  const debounceSearch = useCallback(debounce(searchData, 500), []);

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    dispatch();
    //   getPaymentList({
    //     query: searchQuery,
    //     page: page + 1,
    //     limit: rowsPerPage,
    //     sortby: sortBy,
    //     order: order,
    //   })
  };
  useEffect(() => {
    dispatch(
      getPaymentRequest({
        query: searchQuery,
        page: page,
        limit: rowsPerPage,
        from_date: '',
        to_date: '',
      })
    );
    // dispatch(customerData({}));
    dispatch(getStatPaymentDataRequest({}));
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Position",
        accessor: "position",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Office",
        accessor: "office",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Age",
        accessor: "age",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Salary",
        accessor: "salary",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );
  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];
  const totalPages1 = Math.ceil(getPaymentList?.count / rowsPerPage);

  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1 && currentPage > 3
        ? currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages1 != currentPage ? "..." : null;
  const lastPage =
    currentPage > 3 && totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  //   useEffect(() => {
  //     if (selectScreenData && selectScreenData.length > 0) {
  //       const value = selectScreenData[0]?.actions;
  //       setUserActions(value || {});
  //     }
  //   }, [selectScreenData]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="page-content">
            <Container fluid>
              <div className="content-header row">
                <div className="col-8">
                  <h2>Order Payment</h2>
                </div>
                <div className="col-4 text-end">
                  <button
                    type="button"
                    className="waves-effect waves-light btn btn-primary mb-2"
                    onClick={tog_fullscreen}
                  >
                    Add
                  </button>
                </div>
              </div>
              <Row className="d-none">
                <Col md="3">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Number of Payment Today
                          </p>
                          <h4 className="mb-0">
                            {getPaymentStatData?.stat_today?.count ?? "-"}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className={"ri-stack-line  font-size-24"}></i>
                        </div>
                      </div>
                    </CardBody>

                    {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1"><i className="mdi mdi-menu-down"> </i> 2</span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Payment total Today
                          </p>
                          <h4 className="mb-0">{totToday}</h4>
                        </div>
                        <div className="text-primary">
                          <i className={"ri-funds-line  font-size-24"}></i>
                        </div>
                      </div>
                    </CardBody>

                    {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1"><i className="mdi mdi-menu-down"> </i> 2</span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Number of Payment This Month
                          </p>
                          <h4 className="mb-0">
                            {getPaymentStatData?.stat_month?.count ?? "-"}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className={"ri-stack-line  font-size-24"}></i>
                        </div>
                      </div>
                    </CardBody>

                    {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> 2</span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Payment Total This Month
                          </p>
                          <h4 className="mb-0">{monthTotal}</h4>
                        </div>
                        <div className="text-primary">
                          <i className={"ri-funds-line  font-size-24"}></i>
                        </div>
                      </div>
                    </CardBody>

                    {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> 2</span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
                  </Card>
                </Col>
              </Row>
              <Card>
                <CardHeader>
                  <div className="row">
                    <div className="col-md-8">
                      {/* <h5>Total <span>{totBillAmt.toFixed(2)}</span></h5> */}
                    </div>
                    <div className="col-md-4 row">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        style={{ float: "right" }}
                        // value={searchQuery}
                        onChange={(e) => {
                          // searchData(e);
                          debounceSearch(e.target.value);
                        }}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive react-table">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} stickyHeader>
                        <TableHead className="table-light table-nowrap">
                          <TableRow>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "id" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("id");
                                }}
                              >
                                Receipt No
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "customer" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("customer_name");
                                }}
                              >
                                Vendor
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "payment_type" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("payment_type");
                                }}
                              >
                                Transaction Type
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "payment_date" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("payment_date");
                                }}
                              >
                                Payment Date
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "pay_amt" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("pay_amt");
                                }}
                              >
                                Pay Amount
                              </TableSortLabel>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "pay_mode" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("pay_mode");
                                                        }}
                                                    >
                                                        Pay Mode
                                                    </TableSortLabel> */}
                            {/* </StyledTableCell> */}
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "notes" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("notes");
                                }}
                              >
                                Notes
                              </TableSortLabel>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getPaymentList?.count > 0 &&
                            getPaymentList?.data?.map((row) => (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  {row?.id}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row?.user?.name}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  
                                  {
                                    paymentTypewithcheck.find(
                                      (ele) => ele?.id == row?.payment_type
                                    )?.mode
                                  }
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row?.payment_date &&
                                    dayjs(row?.payment_date).format(
                                      "YYYY-MM-DD"
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row?.pay_amt}
                                </StyledTableCell>
                                {/* <StyledTableCell align="">
                                                            {row?.pay_mode}
                                                        </StyledTableCell> */}
                                <StyledTableCell align="">
                                  {row?.notes ?? "-"}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </CardBody>
              </Card>
              <div className="row">
                <div className="col-10"></div>
                <div className="col-2">
                  {" "}
                  <div className="pagination-area mt-15 mb-50 ">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="material-icons md-chevron_left"></i>
                          </a>
                        </li>

                        {pagesToDisplay.map((page) => {
                          return page != "..." ? (
                            <li
                              key={page}
                              className={`page-item b ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => handlePageChange(page)}
                              >
                                {page}
                              </a>
                            </li>
                          ) : page == "..." ? (
                            <li
                              key={page}
                              className={`page-item ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a className="page-link" href="#">
                                {page}
                              </a>
                            </li>
                          ) : (
                            ""
                          );
                        })}

                        <li
                          className={`page-item ${
                            currentPage === totalPages1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <i className="material-icons md-chevron_right"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default OrderPaymentList;
