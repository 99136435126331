export const orderActionTypes = {
  GETUSERORDER_REQUEST: "@@order/GETUSERORDER_REQUEST",
  GETUSERORDER_SUCCESS: "@@order/GETUSERORDER_SUCCESS",
  GETUSERORDER_FAILURE: "@@order/GETUSERORDER_FAILURE",

  GETALLUSERORDER_REQUEST: "@@order/GETALLUSERORDER_REQUEST",
  GETALLUSERORDER_SUCCESS: "@@order/GETALLUSERORDER_SUCCESS",
  GETALLUSERORDER_FAILURE: "@@order/GETALLUSERORDER_FAILURE",

  ORDERDISPATCH_REQUEST: "@@order/ORDERDISPATCH_REQUEST",
  ORDERDISPATCH_SUCCESS: "@@order/ORDERDISPATCH_SUCCESS",
  ORDERDISPATCH_FAILURE: "@@order/ORDERDISPATCH_FAILURE",

  ORDERDETAILBYID_REQUEST: "@@order/ORDERDETAILBYID_REQUEST",
  ORDERDETAILBYID_SUCCESS: "@@order/ORDERDETAILBYID_SUCCESS",
  ORDERDETAILBYID_FAILURE: "@@order/ORDERDETAILBYID_FAILURE",

  ORDERDSTATUSUPDADE_REQUEST: "@@order/ORDERDSTATUSUPDADE_REQUEST",
  ORDERDSTATUSUPDADE_SUCCESS: "@@order/ORDERDSTATUSUPDADE_SUCCESS",
  ORDERDSTATUSUPDADE_FAILURE: "@@order/ORDERDSTATUSUPDADE_FAILURE",

  CREDITDUEPAYDATA_REQUEST: "@@order/CREDITDUEPAYDATA_REQUEST",
  CREDITDUEPAYDATA_SUCCESS: "@@order/CREDITDUEPAYDATA_SUCCESS",
  CREDITDUEPAYDATA_FAILURE: "@@order/CREDITDUEPAYDATA_FAILURE",

  SREDITDUEPAYDATA_REQUEST: "@@order/SREDITDUEPAYDATA_REQUEST",
  SREDITDUEPAYDATA_SUCCESS: "@@order/SREDITDUEPAYDATA_SUCCESS",
  SREDITDUEPAYDATA_FAILURE: "@@order/SREDITDUEPAYDATA_FAILURE",
  
  GENERATE_REQUEST: "@@order/GENERATE_REQUEST",
  GENERATE_SUCCESS: "@@order/GENERATE_SUCCESS",
  GENERATE_FAILURE: "@@order/GENERATE_FAILURE",
  
  ORDERSTATE_RESET_REQUEST: "@@order/ORDERSTATE_RESET_REQUEST",

  CHECKOUT_POST_REQUEST: "@checkout/CHECKOUT_POST_REQUEST",
  CHECKOUT_POST_SUCCESS: "@checkout/CHECKOUT_POST_SUCCESS",
  CHECKOUT_POST_FAILURE: "@checkout/CHECKOUT_POST_FAILURE",

  GETORDERDATABYID_REQUEST: "@@order/GETORDERDATABYID_REQUEST",
  GETORDERDATABYID_SUCCESS: "@@order/GETORDERDATABYID_SUCCESS",
  GETORDERDATABYID_FAILURE: "@@order/GETORDERDATABYID_FAILURE",
  
  ORDERPAYMENTSEND_REQUEST: "@@order/ORDERPAYMENTSEND_REQUEST",
  ORDERPAYMENTSEND_SUCCESS: "@@order/ORDERPAYMENTSEND_SUCCESS",
  ORDERPAYMENTSEND_FAILURE: "@@order/ORDERPAYMENTSEND_FAILURE",


  GETPAYMENTLIST_REQUEST: "@@order/GETPAYMENTLIST_REQUEST",
  GETPAYMENTLIST_SUCCESS: "@@order/GETPAYMENTLIST_SUCCESS",
  GETPAYMENTLIST_FAILURE: "@@order/GETPAYMENTLIST_FAILURE",

  GETPAYMENTSTATDATA_REQUEST: "@@order/GETPAYMENTSTATDATA_REQUEST",
  GETPAYMENTSTATDATA_SUCCESS: "@@order/GETPAYMENTSTATDATA_SUCCESS",
  GETPAYMENTSTATDATA_FAILURE: "@@order/GETPAYMENTSTATDATA_FAILURE",

  UPDATETRANSPORTPRICE_REQUEST: "@@order/UPDATETRANSPORTPRICE_REQUEST",
  UPDATETRANSPORTPRICE_SUCCESS: "@@order/UPDATETRANSPORTPRICE_SUCCESS",
  UPDATETRANSPORTPRICE_FAILURE: "@@order/UPDATETRANSPORTPRICE_FAILURE",

  WEEKLYPAYDATAGET_REQUEST: "@@order/WEEKLYPAYDATAGET_REQUEST",
  WEEKLYPAYDATAGET_SUCCESS: "@@order/WEEKLYPAYDATAGET_SUCCESS",
  WEEKLYPAYDATAGET_FAILURE: "@@order/WEEKLYPAYDATAGET_FAILURE",
};

export const orderInitialState = {
  userOrderLoading: false,
  userOrderData: [],
  userAllOrderLoading: false,
  userAllOrderData: [],
  orderDisLoad: false,
  orderDetailLoad: false,
  orderDetailData: [],
  updateStatusLoad: false,
  updateStatusSuccess: false,
  creditPayLoad:false,
  creditDueData:[],
  sreditPayLoad:false,
  sreditDueData:[],
  pdfLoad:false,
  pdfSendsuccess:"",
  getpdfDataSuccess:false,
  succesMessage: "",
  checkOutLoad: false,
  getOrderbyUserDetailsloading:false,
  userOrderDetailsById:[],
  paymentLoad:false,
  paymentSuccess:false,
  getPaymentLoad:false,
  getPaymentList:[],
  getPaymentStatLoad:false,
  getPaymentStatData:[],
  TpriceLoading:false,
  TpriceSuccess:false,
  weeklyPayLoad:false,
  weeklypayLoadData:[],

};
export const orderSelector = (state) => state.orderReducer;
