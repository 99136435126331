export const dashboardActionTypes = {
  GETCARDDATA_REQUEST: "@@dashboard/GETCARDDATA_REQUEST",
  GETCARDDATA_SUCCESS: "@@dashboard/GETCARDDATA_SUCCESS",
  GETCARDDATA_FAILURE: "@@dashboard/GETCARDDATA_FAILURE",

  GETDASHBOARDDATA_REQUEST: "@@dashboard/GETDASHBOARDDATA_REQUEST",
  GETDASHBOARDDATA_SUCCESS: "@@dashboard/GETDASHBOARDDATA_SUCCESS",
  GETDASHBOARDDATA_FAILURE: "@@dashboard/GETDASHBOARDDATA_FAILURE",

  PAYMENTDUEDAYS_REQUEST: "@@dashboard/PAYMENTDUEDAYS_REQUEST",
  PAYMENTDUEDAYS_SUCCESS: "@@dashboard/PAYMENTDUEDAYS_SUCCESS",
  PAYMENTDUEDAYS_FAILURE: "@@dashboard/PAYMENTDUEDAYS_FAILURE",

  GETORDERCARDDATA_REQUEST: "@@dashboard/GETORDERCARDDATA_REQUEST",
  GETORDERCARDDATA_SUCCESS: "@@dashboard/GETORDERCARDDATA_SUCCESS",
  GETORDERCARDDATA_FAILURE: "@@dashboard/GETORDERCARDDATA_FAILURE",

  GETPURCHASECARDDATA_REQUEST: "@@dashboard/GETPURCHASECARDDATA_REQUEST",
  GETPURCHASECARDDATA_SUCCESS: "@@dashboard/GETPURCHASECARDDATA_SUCCESS",
  GETPURCHASECARDDATA_FAILURE: "@@dashboard/GETPURCHASECARDDATA_FAILURE",

  GETPURCHASECARDDATA_REQUEST: "@@dashboard/GETPURCHASECARDDATA_REQUEST",
  GETPURCHASECARDDATA_SUCCESS: "@@dashboard/GETPURCHASECARDDATA_SUCCESS",
  GETPURCHASECARDDATA_FAILURE: "@@dashboard/GETPURCHASECARDDATA_FAILURE",

  GETPURCHASEPAYMENTCARDDATA_REQUEST: "@@dashboard/GETPURCHASEPAYMENTCARDDATA_REQUEST",
  GETPURCHASEPAYMENTCARDDATA_SUCCESS: "@@dashboard/GETPURCHASEPAYMENTCARDDATA_SUCCESS",
  GETPURCHASEPAYMENTCARDDATA_FAILURE: "@@dashboard/GETPURCHASEPAYMENTCARDDATA_FAILURE",
};

export const dashboardInitialState = {
  cardLoad: false,
  cardData: [],
  dashLoad: false,
  dashData: [],
  dueLoad:false,
  dueDayData:[],

  ordercardLoad: false,
  ordercardData: [],
  purchasecardLoad: false,
  purchasecardData: [],
  purchasePaymentcardLoad: false,
  purchasePaymentcardData: [],
};

export const dashboardSelector = (state) => state.dashboardReducer;
