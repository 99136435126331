

import { call, all, put, takeEvery } from "redux-saga/effects";
import { RiseTockenActionTypes } from "./rise.model";
import {

  getTockensApi,
  postTockenApi,
  updateTockenApi,
  
} from "./rise.api";
import * as productActions from "./rise.action";
export function* handleGetTockens() {
  
  try {
    const response = yield call(getTockensApi);
    yield put(productActions.riseGetSuccess(response));
  } catch (e) {
    yield put(productActions.riseGetFailure(e));
  }
}

export function* handlePostTocken(action) {
  console.log(action)
  try {
    const response = yield call(postTockenApi,action.payload);
    yield put(productActions.risePostSuccess(response));
  } catch (e) {
    yield put(productActions.risePostFailure(e));
  }
}
export function* handleUpdateTocken(action) {
  console.log(action)
  try {
    const response = yield call(updateTockenApi,action.payload);
    yield put(productActions.riseUpdateSuccess(response));
  } catch (e) {
    yield put(productActions.riseUpdateFailure(e));
  }
}


export function* riseTockenSaga() {
  yield takeEvery(RiseTockenActionTypes.RISETOCKEN_GET_REQUEST, handleGetTockens);
  yield takeEvery(
    RiseTockenActionTypes.RISETOCKEN_POST_REQUEST,
    handlePostTocken
  );
  yield takeEvery(
    RiseTockenActionTypes.RISETOCKEN_UPDATE_REQUEST,
    handleUpdateTocken
  );
 
}
