import { action } from "typesafe-actions";
import { RiseTockenActionTypes } from "./rise.model";

export const riseGetRequest = (req) =>
  action(RiseTockenActionTypes.RISETOCKEN_GET_REQUEST, req);
export const riseGetSuccess = (res) =>
  action(RiseTockenActionTypes.RISETOCKEN_GET_SUCCESS, res);
export const riseGetFailure = (err) =>
  action(RiseTockenActionTypes.RISETOCKEN_GET_FAILURE, err);

export const risePostRequest = (req) =>
  action(RiseTockenActionTypes.RISETOCKEN_POST_REQUEST, req);
export const risePostSuccess = (res) =>
  action(RiseTockenActionTypes.RISETOCKEN_POST_SUCCESS, res);
export const risePostFailure = (err) =>
  action(RiseTockenActionTypes.RISETOCKEN_POST_FAILURE, err);

export const riseUpdateRequest = (req) =>
  action(RiseTockenActionTypes.RISETOCKEN_UPDATE_REQUEST, req);
export const riseUpdateSuccess = (res) =>
  action(RiseTockenActionTypes.RISETOCKEN_UPDATE_SUCCESS, res);
export const riseUpdateFailure = (err) =>
  action(RiseTockenActionTypes.RISETOCKEN_UPDATE_FAILURE, err);

