import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getPCApi = (query) => {
  console.log(query,'qqqq');
  
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/purchasecompany?query" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&id=" 
      // +query.id+"&from_date="+query.from_date+"&to_date="+query.to_date
      ,
    Http.getAuthToken()
  );
};
export const postPCApi = (formData) => {
  console.log(formData, "popo");

  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/purchasecompany/create_purchasecompany",
    formData,
    Http.getAuthToken()
  );
};
export const updatePCApi = (formData, id) => {
  console.log(formData.id, "jjj");
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/purchasecompany/update_purchasecompany/" + formData?.id,
    formData.formData,
    Http.getAuthToken()
  );
};

export const deleteProductApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/purchasecompany/delete_purchasecompany/" + id, Http.getAuthToken());
};
