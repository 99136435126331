import * as yup from "yup";

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  password: yup.string().required("Password is required")
});
const MAX_FILE_SIZE = 102400; //100KB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};
function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

export const educationalScheme = yup.object().shape({
  educations: yup.array().of(
    yup.object().shape({
      education: yup.string().required('Education is required'),
      institution: yup.string().required('Institution is required'),
      percentage: yup.string().required('Percentage is required'),
      from_date: yup.date().required('From Date is required'),
      to_date: yup.date().required('To Date is required')
    })
  )
});

export const certificateScheme = yup.object().shape({
  educations: yup.array().of(
    yup.object().shape({
      description: yup.string(),
      name: yup.string().required('Name is required'),
      conducted_by: yup.string().required('Conducted By is required'),
      grade: yup.string().required('Grade is required'),
      from_date: yup.date().required('From Date is required'),
      to_date: yup.date().required('To Date is required')
    })
  )
});

export const myQuestionSchema = yup.object().shape({
  // post_content:yup.string(),
  post_topic: yup.string().required("Topic is required"),
  // category_id: yup.number()
  // postMedia:yup.mixed()
  // .required("Required")
  // .test("is-valid-type", "Not a valid image type",
  //   value => isValidFileType(value && value.name?.toLowerCase(), "image"))
  // .test("is-valid-size", "Max allowed size is 100KB",
  //   value => value && value.size <= MAX_FILE_SIZE)
});

export const myAnswerSchema = yup.object().shape({
  // post_content:yup.string(),
  answer: yup.mixed().required("Required"),
  // category_id: yup.number()
  // postMedia:yup.mixed()
  // .required("Required")
  // .test("is-valid-type", "Not a valid image type",
  //   value => isValidFileType(value && value.name?.toLowerCase(), "image"))
  // .test("is-valid-size", "Max allowed size is 100KB",
  //   value => value && value.size <= MAX_FILE_SIZE)
});
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const hiringPartnerSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  phone_no: yup
    .string()
    .required("Phone number is required")
    .test("phone", "Phone is invalid", (value) => {
      return validatePhone(parseInt(value ?? "0"));
    }),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRules, { message: "Please create a stronger password" }),
  // address: yup.string().required("Address is required"),
});

export const edithiringPartnerSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  phone_no: yup
    .string()
    .required("Phone number is required")
    .test("phone", "Phone is invalid", (value) => {
      return validatePhone(parseInt(value ?? "0"));
    }),
});

export const signUpValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  phone_no: yup
    .string()
    .required("Phone number is required")
    .test("phone", "Phone is invalid", (value) => {
      return validatePhone(parseInt(value ?? "0"));
    }),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRules, { message: "Please create a stronger password" }),
  college: yup.string().required("College is required"),
  roll_no: yup.string().required("Roll No is required"),
  dob: yup.string().required("DOB is required"),
});

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email);
};

export const userValidationSchema = yup.object().shape({
  first_name: yup.string().required("User First Name is required"),
  last_name: yup.string().required("User Last Name is required"),
  dob: yup.string().required("User Date Of Birth is required"),
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
    }),
  phone_number: yup
    .number()
    .transform((_, val) => (val !== "" ? Number(val) : null))
    .required("Phone is required"),
});
export const otpValidationSchema = yup.object().shape({
  document_number: yup.string().required("Document Number is required"),
  otp: yup.string().required("OTP is required"),
});
const validatePhone = (phone) => {
  return yup
    .number()
    .integer()
    .positive()
    .test((phone) => {
      return phone &&
        phone.toString().length >= 8 &&
        phone.toString().length <= 14
        ? true
        : false;
    })
    .isValidSync(phone);
};
export const vendorCreate=yup.object().shape({
  company_name:yup.string().required("Company name is required"),
  contact_person:yup.string().notRequired(),
  contact_number:yup.string().notRequired(),
  contact_email:yup.string().notRequired(),
  gst_no:yup.string().notRequired()
})
export const addInvoice=yup.object().shape({

})
export const paymentValidator = yup.object().shape({
  // sales_person: yup.string(),
  invoice_bal: yup.string(),
  pay_amt: yup.number().required("Pay Amount Only in Number"),
  notes: yup.string(),
})