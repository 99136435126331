import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getPOApi = (query) => {
  console.log(query, "on change");

  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/purchase?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&companyId=" +
      query.companyId +
      "&date_from=" +
      query.date_from +
      "&date_to=" +
      query.date_to +
      "&orderStatus=" +
      query.orderStatus,
    Http.getAuthToken()
  );
};
export const postPOApi = (formData) => {
  console.log(formData, "popo");

  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/purchase/create_invoice",
    formData,
    Http.getAuthToken()
  );
};
export const updatePOApi = (formData, id) => {
  console.log(formData, "jssjj");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/purchase/update_invoice/",
    formData,
    Http.getAuthToken()
  );
};

export const getPOByIdApi = (query) => {
  console.log(query, "jjssj");

  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/purchase/get_invoice_by_id/" + query.id,
    Http.getAuthToken()
  );
};

export const pdfUpladApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/purchase/update_pdf",
    formData,
    Http.getFileAuthToken()
  );
};

export const createPurchasePayment = (data) => {
  console.log(data, "dadfdfta");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/purchase/payment/create",
    data,
    Http.getAuthToken()
  );
};

export const getPruchasePayments = (query) => {
  console.log(query, "54678");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/purchase/payment?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&date_from=" +
      query.from_date +
      "&date_to=" +
      query.to_date,
    Http.getAuthToken()
  );
};
export const getFilteredPruchasePayments = (query) => {
  console.log(query, "54678");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/purchase/get_invociebycompany?query" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&companyId=" +
      query.companyId,
    Http.getAuthToken()
  );
};
