import { action } from "typesafe-actions";
import { dashboardActionTypes } from "./dashboard.model";

export const getCardDataRequest = (req)=>
    action(dashboardActionTypes.GETCARDDATA_REQUEST, req);
  export const getCardDataSuccess=(res)=>
    action(dashboardActionTypes.GETCARDDATA_SUCCESS, res);
  export const getCardDataFailure=(err)=>
    action(dashboardActionTypes.GETCARDDATA_FAILURE, err);
  
  export const getDashBoardDataRequest = (req)=>
    action(dashboardActionTypes.GETDASHBOARDDATA_REQUEST, req)
  export const getDashBoardDataSuccess=(res)=>
    action(dashboardActionTypes.GETDASHBOARDDATA_SUCCESS, res)
  export const getDashBoardDataFailure=(err)=>
    action(dashboardActionTypes.GETDASHBOARDDATA_FAILURE, err)


  export const dueDayDataRequest = (req)=>
    action(dashboardActionTypes.PAYMENTDUEDAYS_REQUEST, req)
  export const dueDayDataSuccess=(res)=>
    action(dashboardActionTypes.PAYMENTDUEDAYS_SUCCESS, res)
  export const dueDayDataFailure=(err)=>
    action(dashboardActionTypes.PAYMENTDUEDAYS_FAILURE, err)

  export const getOrderCardDataRequest = (req)=>
    action(dashboardActionTypes.GETORDERCARDDATA_REQUEST, req);
  export const getOrderCardDataSuccess=(res)=>
    action(dashboardActionTypes.GETORDERCARDDATA_SUCCESS, res);
  export const getOrderCardDataFailure=(err)=>
    action(dashboardActionTypes.GETORDERCARDDATA_FAILURE, err);

  export const getPurchseCardDataRequest = (req)=>
    action(dashboardActionTypes.GETPURCHASECARDDATA_REQUEST, req);
  export const getPurchseCardDataSuccess=(res)=>
    action(dashboardActionTypes.GETPURCHASECARDDATA_SUCCESS, res);
  export const getPurchseCardDataFailure=(err)=>
    action(dashboardActionTypes.GETPURCHASECARDDATA_FAILURE, err);

  export const getPurchsePaymentCardDataRequest = (req)=>
    action(dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_REQUEST, req);
  export const getPurchsePaymentCardDataSuccess=(res)=>
    action(dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_SUCCESS, res);
  export const getPurchsePaymentCardDataFailure=(err)=>
    action(dashboardActionTypes.GETPURCHASEPAYMENTCARDDATA_FAILURE, err);