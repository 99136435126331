import { call, put, takeEvery } from "redux-saga/effects";
import {
  bulkCreateProductApi,
  deleteProductApi,
  getcATProductApi,
  getProductApi,
  postProductApi,
  postProductPriceApi,
  updateProductApi,
} from "./product.api";
import * as productAction from "./product.action";
import { productActionTypes } from "./product.model";
import Swal from "sweetalert2";

function* handlepostProductData(action) {
  try {
    const response = yield call(postProductApi, action.payload);
    yield put(productAction.postProductSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Product List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.postProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlegetProductData(action) {
  try {
    const response = yield call(getProductApi, action.payload);
    yield put(productAction.getProductSuccess(response));
  } catch (e) {
    yield put(productAction.getProductFailure(e));
  }
}

function* handleUpdateProductData(action) {
  try {
    const response = yield call(updateProductApi, action.payload);
    yield put(productAction.UpdateProductSuccess(response));

    Swal.fire({
      title: "Success",
      text: "Product List Updated Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.UpdateProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
function* handleDeleteProductData(action) {
  try {
    const response = yield call(deleteProductApi, action.payload);
    yield put(productAction.deleteProductSuccess(response));

    Swal.fire({
      title: "Success",
      text: "Product List Delete Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.deleteProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlepostProductPriceData(action) {
  try {
    const response = yield call(postProductPriceApi, action.payload);
    yield put(productAction.postProductPriceSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Product List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.postProductPriceFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
function* handlegetCatProductData(action) {
  try {
    const response = yield call(getcATProductApi, action.payload);
    yield put(productAction.getProductPriceSuccess(response));
  } catch (e) {
    yield put(productAction.getProductPriceFailure(e));
  }
}
// function* handlegetAllCatProductData(action) {
//   try {
//     const response = yield call(getcATProductApi, action.payload);
//     yield put(productAction.getAllProductPriceSuccess(response));

//   } catch (e) {
//     yield put(productAction.getAllProductPriceFailure(e));
//   }
// }
function* handleBulkUpdate(action) {
  try {
    const response = yield call(bulkCreateProductApi, action.payload);
    yield put(productAction.postBulkProductSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Product Bulk Upload Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.postBulkProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
export function* productSaga() {
  yield takeEvery(
    productActionTypes.POSTPRODUCT_REQUEST,
    handlepostProductData
  );
  yield takeEvery(productActionTypes.GETPRODUCT_REQUEST, handlegetProductData);

  yield takeEvery(
    productActionTypes.UPDATEPRODUCT_REQUEST,
    handleUpdateProductData
  );

  yield takeEvery(
    productActionTypes.DELETEPRODUCT_REQUEST,
    handleDeleteProductData
  );
  yield takeEvery(
    productActionTypes.POSTPRODUCTPRICE_REQUEST,
    handlepostProductPriceData
  );
  yield takeEvery(
    productActionTypes.GETPRODUCTCAT_REQUEST,
    handlegetCatProductData
  );
  // yield takeEvery(
  //   productActionTypes.GETALLPRODUCTCAT_REQUEST,
  //   handlegetAllCatProductData
  // );
  yield takeEvery(productActionTypes.POSTBULKPRODUCT_REQUEST, handleBulkUpdate);
}
