export const productActionTypes = {
  POSTPRODUCT_REQUEST: "@@product/POSTPRODUCT_REQUEST",
  POSTPRODUCT_SUCCESS: "@@product/POSTPRODUCT_SUCCESS",
  POSTPRODUCT_FAILURE: "@@product/POSTPRODUCT_FAILURE",

  GETPRODUCT_REQUEST: "@@product/GETPRODUCT_REQUEST",
  GETPRODUCT_SUCCESS: "@@product/GETPRODUCT_SUCCESS",
  GETPRODUCT_FAILURE: "@@product/GETPRODUCT_FAILURE",

  UPDATEPRODUCT_REQUEST: "@@product/UPDATEPRODUCT_REQUEST",
  UPDATEPRODUCT_SUCCESS: "@@product/UPDATEPRODUCT_SUCCESS",
  UPDATEPRODUCT_FAILURE: "@@product/UPDATEPRODUCT_FAILURE",

  DELETEPRODUCT_REQUEST: "@@product/DELETEPRODUCT_REQUEST",
  DELETEPRODUCT_SUCCESS: "@@product/DELETEPRODUCT_SUCCESS",
  DELETEPRODUCT_FAILURE: "@@product/DELETEPRODUCT_FAILURE",

  POSTPRODUCTPRICE_REQUEST: "@@product/POSTPRODUCTPRICE_REQUEST",
  POSTPRODUCTPRICE_SUCCESS: "@@product/POSTPRODUCTPRICE_SUCCESS",
  POSTPRODUCTPRICE_FAILURE: "@@product/POSTPRODUCTPRICE_FAILURE",

  GETPRODUCTCAT_REQUEST: "@@product/GETPRODUCTCAT_REQUEST",
  GETPRODUCTCAT_SUCCESS: "@@product/GETPRODUCTCAT_SUCCESS",
  GETPRODUCTCAT_FAILURE: "@@product/GETPRODUCTCAT_FAILURE",

  // GETALLPRODUCTCAT_REQUEST: "@@product/GETALLPRODUCTCAT_REQUEST",
  // GETALLPRODUCTCAT_SUCCESS: "@@product/GETALLPRODUCTCAT_SUCCESS",
  // GETALLPRODUCTCAT_FAILURE: "@@product/GETALLPRODUCTCAT_FAILURE",

  STATE_RESET_REQUEST:"@@product/STATE_RESET_REQUEST",

  POSTBULKPRODUCT_REQUEST: "@@product/POSTBULKPRODUCT_REQUEST",
  POSTBULKPRODUCT_SUCCESS: "@@product/POSTBULKPRODUCT_SUCCESS",
  POSTBULKPRODUCT_FAILURE: "@@product/POSTBULKPRODUCT_FAILURE",

};

export const productInitialState = {
  postProLoading: false,
  productSendSuccess: false,
  getProLoading: false,
  updateProLoading: false,
  productUpdateSuccess: false,
  getProductData: [],
  deleteproductLoading: false,
  productDeleteSucces: false,
  postPriceLoad:false,
  postPriceSuccess:false,
  catLoading:false,
  cat:[],
  postBulkProLoading: false,
  productBulkSendSuccess: true,
  // allcatLoading:false,
  // allcat:[]
};

export const productSelector = (state) => state.productReducer;
