import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getcardsApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/dashboard/carddata`, Http.getAuthToken());
};
export const getDashboardApi = (query) => {
  console.log(query, 77834567);
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/dashboard/chartdata/?period=" + query.period,
    Http.getAuthToken()
  );
};
export const getDueDayApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/dashboard/payment`, Http.getAuthToken());
};

export const getOrdercardsApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/orders/cartdata/`, Http.getAuthToken());
};

export const getPurchasecardsApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/purchase/getcartdata/`, Http.getAuthToken());
};

export const getPurchasePaymentcardsApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/purchase/payment/stat/`, Http.getAuthToken());
};
